@import "../color_variables";

#wrapper {
    padding: 1.5em;
}

.inputs_block,
.selectors_block,
.search_by_category_links {
    display: flex;
    flex-direction: row;
}

.search_title {
    font-size: 1.5em;
}

.centering {
    justify-content: center;
}

.search_title,
.search_by_alphabet_title,
.search_by_category_title {
    font-weight: bold;
    color: $black;
}

.search_input {
    color: $black-form-text;
    width: 55%;
    height: 3.5em;
    padding-left: 1em;
    border: thin solid $gray-form-border;
    border-right: none;
    background-color: $gray-form-background;
    border-top-left-radius: 0.4em;
    border-bottom-left-radius: 0.4em;
}

.search_input::placeholder {
    font-size: 0.9em;
}

.search_button {
    width: 2.5em;
    // height: 2.5em;
    background-color: $blue-button-and-links;
    border: none;
    border-top-right-radius: 0.4em;
    border-bottom-right-radius: 0.4em;
    color: $white;
    padding: 0.25em 0.5em;

    img {
        width: 1.5em;
        height: 1.5em;
    }
}

.search_button:hover {
    background-color: $logo_brown;
    cursor: pointer;
}

.selectors_block {
    margin-top: 2.5em;
}

.main_selector,
.language_selector,
.results_selector {
    width: 23%;
    height: 3.3em;
    color: $black-form-text;
    border: thin solid $gray-form-border;
    cursor: pointer;
    padding-left: 0.6em;
}

.form-control:focus {
    color: $black-form-text;
    border: thin solid $gray-form-border;
}
.language_selector,
.results_selector {
    margin-left: 2.5rem;
}

.search_by_alphabet_title,
.search_by_category_title {
    font-size: 1.2em;
    margin-top: 4.5em;
    color: $black;
    font-weight: bold;
}

.alphabet_button_block {
    width: 40%;
    margin-left: -1.3%;

    .alphabet_button {
        &:last-child {
            width: 4rem;
        }
    }
}

.search_by_category_title {
    margin-top: 2em;
}

.search_by_category_links p a {
    font-size: 0.9em;
    font-weight: bold;
    color: $blue-button-and-links !important;
    cursor: pointer;
}

.search_by_category_links p a:hover {
    color: $blue-button-and-links-hovered !important;
}

.search_by_category_links_block1 {
    padding-right: 5em;
}

.search_by_category_links_block2 {
    padding-bottom: 8em;
}

.search_by_category_links p {
    margin: 0;
}

@media all and (max-width: 775px) {
    .selectors_block,
    .search_by_category_links {
        display: block;
    }

    .alphabet_button_block {
        width: 80%;
    }

    .language_selector,
    .results_selector {
        margin-left: 0;
        margin-top: 10px;
    }

    .main_selector,
    .language_selector,
    .results_selector {
        width: 60%;
    }
}

.bg-white {
    background-color: transparent !important;
}

.search_icon {
    margin-top: 52%;
    margin-left: 22%;
    color: white !important;
}

@media screen and (max-width: 800px) {
    .search_input {
        width: 100%;
        height: 3rem;
    }

    .search_icon {
        margin-top: 42%;
        margin-left: 20%;
    }
    .main_selector,
    .results_selector {
        width: 100%;
    }
    .alphabet_button_block {
        width: 100%;
        margin-left: -1rem;
    }
}

//my styles
.main-title-advanced-search {
    font-weight: bold;
    color: $black;
    white-space: nowrap;
    font-size: 2rem;
}

.search_button {
    display: inline-flex;
    align-items: center;
    justify-items: center;
    background-color: $orange_color;
}

.inputs_block {
    width: 100%;
    max-width: 600px;
    border: 2px solid #d1cfd0;
    border-radius: 7px;

    .react-autosuggest__container {
        border-left: unset;
        margin-top: 2px;
    }
}
