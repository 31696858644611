@import "../color_variables";

.subscription-history-container {
	table {
		width: 100%;
		text-align: center;

		td,
		th {
			padding-bottom: 12.5px;
			padding-top: 12.5px;
		}

		th {
			font-size: 16px;
			color: #000000;
		}

		td {
			font-size: 13px;
			color: #000000;

			&.name {
				font-size: 16px;
				color: #016063;
			}

			&.status {
				text-decoration: underline;

				&.Active {
					color: #016063;
				}

				&.Canceled {
					color: #f9103f;
				}

				&.Pending {
					color: #f9103f;
				}
			}
		}
	}

	.MuiTab-root {
		font-size: 13px;
		min-width: unset;
	}
	.navigation-buttons {
		align-self: center;
		font-size: 13px;
	}
	.MuiTabs-indicator {
		background-color: #eef2f8;
		// height: 100%;
	}

	.MuiTabs-flexContainer {
		background-color: #ffff;
	}

	.MuiTab-textColorInherit.Mui-selected {
		opacity: 1;
		background-color: #eef2f8;
		border-top-left-radius: 14px;
		border-top-right-radius: 14px;
		outline: 0;
	}

	.MuiBox-root {
		background-color: #eef2f8;
		border-bottom-left-radius: 24px;
		border-bottom-right-radius: 24px;
		border-top-right-radius: 24px;
	}

	.drugs-table-empty {
		padding: 16px 0;
		text-align: center;
	}
	
}