/* VARIABLES */

// Font sizes
$large-title-font-size: 36px;
$medium-title-font-size: 20px;
$large-label-font-size: 25px;
$medium-label-font-size: 13px;
$medium-label-mini-font-size: 12px;
$input-medium-font-size: 13px;
// Colors
$blue-main: #0279bd;
$green-main: #7eccc4;
$button-blue: #03a8f5;
$button-blue-hover: #049fe7;
$button-dark-blue: #045f94;
$button-dark-blue-hover: #117cb9;
$auth-button: #eef2f8;
$auth-button-hover: #dde7f7;
$close-color: #c7c7c7;
$close-hover-color: #a09f9f;
$modal-background-color: #f7f9fc;
$input-border-color: #c8c6c7;
$error-color: #f12626;
$error-border-color: #c72121;
// Sizes
$auth-button-height: 33px;
$input-height: 33px;
$mini-input-height: 26px;
$textarea-height: 74px;
// Margins
$modal-footer-margin-top: 25px;
$standart-margin-bottom: 8px;
// Paddings
$standart-padding: 32px;

/* ---------------------------- */
