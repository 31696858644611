.admin-tabs-nav {
    & .active.nav-item {
        background-color: #5c6483;
        color: #fff;
    }
}

.nav-tabs {
    & .nav-item {
        cursor: pointer;
    }
}

.close-button {
    cursor: pointer;
    &:hover {
        color: gray;
    }
}

.flex-column.nav-tabs.nav-pills {
    position: fixed;
    top: 11em;
    & .nav-item:not(:last-child) {
        padding: 0;
    }
}

.ck.ck-balloon-panel {
    z-index: 2000;
}

.lang-changer {
    z-index: 2000;
}

.unvisible {
    display: none;
}
