.faq {
    font-family: Arial, Helvetica, sans-serif;
    display: inline;
    .faq-container {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding: 30px 0 65px 0;
    }
    &__block {
        width: 80%;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-direction: column;

        @media screen and (max-width: 440px) {
            width: 90%; 
        }
    }
    &__title {
        font-size: 20px;
        font-weight: bold;
    }
}