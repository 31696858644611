$almost-black: #242424;
$light-blue-for-background: #e5f3fa;
$black: rgba(0,0,0,0.7);
$white: #fff;

$gray-form-background: #fafafa;
$gray-form-border: #dfdfdf;
$gray-form-text: #707070;
$lightgray-form-text: #a0a0a0;
$black-form-text: #111111;

$blue-button-and-links: #1592e6;
$blue-button-and-links-hovered: #1a7cbe;

$orange_color: #ff6e41;
$orange_hover: #f77751;
$logo_brown: #732d1c;

$carousel_mobileApp_color: #f6fafd;
$search_field_grey: #d1cfd0;
$tab_header_grey: #f1eff0;
$second-text-color: #722D1D;
$third-text-color: #763223;
$menu-color: #783627;
