//
// Navbar search
//

.navbar-search {
    .input-group {
        border-radius: $navbar-search-border-radius;
        border: $navbar-search-border-width solid;
        background-color: transparent;

        .input-group-text {
            background-color: transparent;
            padding-left: 1rem;
            padding-top: 0.3rem;
            padding-right: 1rem;
            padding-bottom: 0.3rem;
        }

        .search-filter {
            background: transparent;
            border: none;
            color: white;
            font-weight: normal;
            padding: 0;
        }
        .search-filter:focus {
            background: transparent;
        }
        .search-filter:active {
            color: #fff;
        }
    }

    .form-control {
        min-width: $navbar-search-width;
        background-color: transparent;
    }
}

.dropdownItemPointer:hover {
    cursor: pointer;
}

.navbar-search-dark {
    .input-group {
        border-color: $navbar-search-dark-border-color;
    }

    .input-group-text {
        color: $navbar-search-dark-color;
    }

    .form-control {
        color: $navbar-search-dark-focus-color;

        &::placeholder {
            color: $navbar-search-dark-color;
        }
    }

    .focused {
        .input-group {
            border-color: $navbar-search-dark-focus-border-color;
        }
    }
}

.navbar-search-light {
    .input-group {
        border-color: $navbar-search-light-border-color;
        background-color: $navbar-search-light-bg-color;
    }

    .input-group-text {
        color: $navbar-search-light-color;
    }

    .form-control {
        color: $navbar-search-light-focus-color;

        &::placeholder {
            color: $navbar-search-light-color;
        }
    }

    .focused {
        .input-group {
            border-color: $navbar-search-light-focus-border-color;
        }
    }
}
