@import "./../color_variables";
.classes_page {
   
    .classes_title {
        font-weight: bold;
        color: $black;
        font-size: 1rem;
    }
    .classes_body {
        font-size: 1rem;
        color: $black;
        a {
            color: $orange_color;
            &:hover {
                text-decoration: underline;
            }
        }
        max-width: 1000px;
    }
    .search_card {
        padding-top: 0;
        padding-bottom: 0;
        .search_field {
            margin-top: 10px;
            margin-bottom: 20px;
        }
    }
    .main_content {
        width: 100%;
    }
    .main_content_sda_wrapper {
        display: flex;
    }
    &__tree {
        padding-left: 20px;
        list-style: none;
        margin-bottom: 0;
        background: url('../../../assets/img/vertical-lines.webp') 0 0 no-repeat;
        //background-position: 0 -176px;
        span {
            border: 1px solid #ff6e41;
            height: 19px;
            width: 19px;
            display: inline-block;
            text-align: center;
            line-height: 19px;
            color: #ff6e41;
            margin-right: 5px;
            cursor: pointer;
            svg {
                margin-top: -7px;
                margin-left: -6px;
            }
        }
    }
    &__classification {
        margin-top: 20px;
    }
}

@media (max-width: 768px) {
    .main_content_sda_wrapper {
        flex-direction: column;
        .sda_block {
            margin-left: auto;
            margin-right: auto;
            margin-bottom: 15px;
        }
    }
}