@import "./../color_variables";
.main-title {
    font-weight: bold;
    color: $black;
    white-space: nowrap;
    font-size: 2rem;
}
@media (max-width: 768px) {
    .main-title {
        white-space: normal;
    }
}

.slick-next:before,
.slick-prev:before {
    display: none;
}

.slick-prev,
.slick-next {
    background-size: contain !important;
    background-repeat: no-repeat !important;
}

.slick-next {
    background-image: url("./img/arrowNext.webp") !important;
}

.slick-prev {
    background-image: url("./img/arrowPrevious.webp") !important;
}

.slider-box {
    background-color: white;
    box-shadow: 0 0 0.4rem lightgray;
    padding-top: 15px;
    padding-bottom: 15px;
}

.slick-slide img,
.slick-active img {
    height: 58px;
    margin-left: auto;
    margin-right: auto;
}

.slick-slide h3,
.slick-active h3 {
    // font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Segoe UI Symbol", Roboto, "Helvetica Neue", Arial, sans-serif;
    font-size: 1.2rem;
    text-align: center;
}

.slider-item {
    img {
        margin: auto;
    }
    h3 {
        margin-top: 10px;
        margin-bottom: 0;
        font-size: 0.9rem;
        white-space: nowrap;
        font-weight: 600 !important;
    }
}

.slick-prev {
    left: 1.5% !important;
    top: 50% !important;
}

.slick-next {
    right: 1.5% !important;
    top: 50% !important;
}

.slick-list {
    width: 85%;
    margin-right: auto;
    margin-left: auto;
}

.main-page-content-block {
    padding-top: 48px;
    padding-bottom: 26.5px;
    // margin: 0 -15px;
    background-color: #fff;
    padding-left: 32px;
    padding-right: 32px;
    box-shadow: 0 0 0.4rem lightgrey;
}

.browse-drugs,
.browseSectionsHeader {
    display: inline-block;
    text-align: center;
    color: $white !important;
    background-color: $orange_color;
    padding-top: 0.3rem;
    padding-bottom: 0.3rem;
    padding-left: 1rem;
    padding-right: 1rem;
    font-size: 1rem;
    border-radius: 5px 5px 0 0;
    margin-bottom: 0px;
    margin-left: 0px;
    white-space: nowrap;
}

.alphabet-block-main-page,
.browseSectionsContent {
    border-top: solid 2px $orange_color;
    padding-top: 15.5px;
    margin-top: -1px;
}

.alphabet-block-main-page {
    width: calc(100% - 25px);
    .alphabet_button {
        &:nth-last-child(2) {
            width: 7.8rem;
        }
        &:last-child {
            width: 13rem;
        }
    }
}

.browseSectionsContent {
    padding-left: 0px;
    padding-right: 0px;
    .row {
        margin-bottom: 0.5rem;
        .col,
        .col-7 {
            padding-left: 0px;
        }
        a {
            color: $second-text-color;
            font-size: 1rem;
            &:hover {
                color: $orange_color;
            }
        }
    }
}

.search-links {
    padding-top: 10px;
    &__link {
        color: #722d1d;
        &:hover {
            color: #ff6e41;
        }
    }
}

.news-block {
    margin-top: 1.5rem;
    width: 32%;
    background-color: white;
    padding: 1.5rem;
    box-shadow: 0 0 0.3em lightgray;
    float: left;
    margin-right: 0.9rem;
}

.news-block:hover {
    background-color: #f9f9f9;
    box-shadow: 0 0 0.3em #a0a0a0;
    cursor: pointer;
}

.news-block img {
    width: 100%;
    height: 12rem;
    margin-bottom: 0.7rem;
}

.news-title {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    @media (max-width: 740px) {
        padding-top: 10px;
    }
}

.news-title:hover {
    text-decoration: underline;
    cursor: pointer;
}

.news-description {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    height: 6rem;
}

.news-description:hover {
    cursor: pointer;
    text-decoration: underline;
}

.mobileAppImg {
    margin: 1rem 0 0 1rem;
    float: left;
}

.mob-desc-p {
    width: 25rem;
}

.slider-box {
    background-color: $carousel_mobileApp_color !important;
    // margin-left: -15px;
    // margin-right: -15px;
}

.search-box {
    p {
        margin-top: 15px;
        a {
            color: $orange_color;
        }
    }

    @media screen and (max-width: 768px) {
        margin-bottom: 2em;
    }
}

.slider_item_header_inactive {
    color: rgba($color: #000000, $alpha: 0.3) !important;
}

.news_sda_block {
    display: flex;
    margin-left: 15px;
    margin-bottom: 15px;
    & > div {
        margin-right: 15px;
    }
    .container_of_news_blocks {
        display: flex;
        flex-direction: column;
    }
}

@media (max-width: 991px) {
    .slider-item {
        h3 {
            font-size: 1.6vw;
        }
    }
}

@media (max-width: 768px) {
    .slider-box {
        margin-left: 0px;
        margin-right: 0px;
    }
    .main-page-content-block {
        padding-top: 15px;
        padding-bottom: 15px;
        // width: 93.6%;
        margin-left: auto;
        margin-right: auto;
        & > .container {
            padding-left: 0;
            padding-right: 0;
            & > .row {
                flex-direction: column;
                & > .col {
                    flex-basis: 100%;
                }
            }
        }
    }
    .alphabet-block-main-page {
        width: 100%;
    }

    .news_sda_block {
        flex-direction: column;
        margin-right: 15px;
        & > div {
            margin-right: 0;
        }
        .sda_block {
            margin-left: auto;
            margin-right: auto;
            margin-bottom: 15px;
        }
        .news_block {
            margin-left: auto;
            margin-right: auto;
        }
    }
}
.live-button {
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition-property: -webkit-transform, opacity;
    transition-property: transform, opacity;

    &:hover {
        -webkit-transform: translateY(-3px);
        -ms-transform: translateY(-3px);
        transform: translateY(-3px);
        text-decoration: none;
        color: #353240;
    }

    &:hover:before {
        opacity: 1;
        -webkit-transform: translateY(5px);
        -ms-transform: translateY(5px);
        transform: translateY(5px);
    }
}
@media (max-width: 500px) {
    .slider-box {
        h3 {
            font-size: 2.5vw;
        }
    }
}
@media (max-width: 400px) {
    .slider-box {
        h3 {
            font-size: 2.6vw;
        }
    }

    .browseSectionsContent {
        padding-left: 0px;
        padding-right: 0px;
        .row {
            margin-bottom: 0.5rem;
            .col,
            .col-7 {
                flex-basis: inherit;
            }
            a {
                color: $second-text-color;
                font-size: 1rem;
                &:hover {
                    color: $orange_color;
                }
            }
        }
    }
}
