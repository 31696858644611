@import "../color_variables";

.sda_block {
    display: flex;
    flex-direction: column;
    padding-top: 5px;
    &__geneal a {
        line-height: 3em;
    }

    & > div {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        h3 {
            color: $orange_color !important;
            font-size: 1.1rem;
        }

        a {
            color: $second-text-color;
            font-size: 0.9rem;
            line-height: 2.6;

            &:last-of-type {
                margin-bottom: 0;
            }
            &:hover {
                color: #ff6e41 !important;
            }
            &:active {
                color: #ff6e41 !important;
            }
        }
    }
}

.sda_placeholder {
    // border: 1px solid black;
    // width: 100px;
    // height: 460px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;

    img {
        object-fit: cover;
        width: 15rem;
        margin: 1rem 0;
    }
}

@media (max-width: 900px) {
    .sda_placeholder {
        img {
            width: 12rem;
        }
    }
}
@media (max-width: 815px) {
    .sda_placeholder {
        img {
            width: 9.8rem;
        }
    }
}
@media (max-width: 768px) {
    .sda_placeholder {
        img {
            width: 80%;
            display: flex;
            flex-direction: column;
            align-items: center;
            align-content: center;
        }
    }
}
