@import "./../color_variables";
.all-news_main_container {
    display: flex;
    background-color: #fff;
    border-radius: 24px 24px 0 0;
}
@media (max-width: 768px) {
    .all-news_main_container {
        display: block;
    }
}
.all-news_container {
    border-radius: 24px 24px 0 0;
    .all-news_content {
        width: 90%;
    }
    @media (max-width: 768px) {
        .all-news_content {
            width: 100%;
        }
    }
}
.main-title {
    font-weight: bold;
    color: $black;
    white-space: nowrap;
    font-size: 2rem;
}
@media (max-width: 768px) {
    .main-title {
        white-space: normal;
    }
    .mob {
        padding-bottom: 0;
    }
}
.list {
    display: grid;
}
.news {
    &__newdrug {
        padding: 2rem;
        width: 90%;
        a {
            color: $orange_color !important;
        }
        .search_field {
            margin: 0 auto !important;
            width: 70%;
            padding: 1rem;
            @media (max-width: 768px) {
                width: 100%;
                padding: 0;
            }
        }
        .card-body {
            padding: 0;
        }
    }
}

.last-news-main__sda {
    h3 {
        color: $orange_color !important;
        font-size: 1.1rem;
    }
    a {
        color: black;
        font-size: 0.9rem;
        line-height: 25px;
        &:hover {
            color: #ff6e41 !important;
        }
        &:active {
            color: #ff6e41 !important;
        }
    }
}

.main_content {
    // margin-left: 24px;
    .news_title {
        font-weight: bold;
        color: $second-text-color;
        font-size: 1.5rem;
    }
    .news_body {
        display: flex;

        a {
            color: $orange_color;
            &:hover {
                text-decoration: underline;
            }
        }
        .popular_requests_container,
        .categories_container {
            & > div {
                margin-bottom: 15px !important;

                &:last-of-type {
                    margin-bottom: 0 !important;
                }

                & > div {
                    margin-right: 50px !important;
                    width: 25%;
                    // word-break: break-all;

                    &:last-of-type {
                        margin-right: 0 !important;
                    }
                }
            }
        }
    }
}

@media (max-width: 768px) {
    .col-sm-12 {
        padding-right: 0;
        padding-left: 0;
    }
}
