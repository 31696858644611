@import "../../variables.scss";
.text-input-component {
    display: flex;
    align-items: center;
    margin-bottom: $standart-margin-bottom;
    position: relative;
    p {
        font-size: $medium-label-font-size;
        width: 150px;
        margin-bottom: 0;
    }
    input {
        border: 1px solid $input-border-color;
        font-size: $input-medium-font-size;
        height: $input-height;
        outline: none;
        width: 100%;
        border-radius: 5px;
        box-shadow: 0px 1px 3px -2px rgba(0, 0, 0, 0.6);
        padding-left: 12px;
        &.error {
            border: 1px solid $error-border-color !important;
        }
    }
    &__error {
        position: absolute;
        width: fit-content !important;
        color: $error-color !important;
        left: 100%;
        top: 100%;
        transform: translate(calc(-100% - 8px), calc(-100% - 2px));
    }
    input[type="date"]::-webkit-inner-spin-button,
    input[type="date"]::-webkit-calendar-picker-indicator {
        // display: none;
        -webkit-appearance: none;
    }

    label {
        font-size: 13px;
        text-align: left;
        width: 1000%;
        margin-bottom: 4px;
        margin-top: 4px;
    }
}

.personal-cabinet-patient-styles_text-input {
    display: flex;
    width: 80%;
    margin-left: auto;
    p {
        font-size: 13px;
        font-weight: bold;
        color: #404040;
        &:nth-of-type(1) {
            min-width: 108px;
        }
    }
    input {
        box-shadow: none;
        font-size: 13px;
        color: #404040;
        background-color: #eef2f8;
        border: unset;
        pointer-events: none;
        padding-top: 4px;
    }
}

.mini-text-input-component {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: $standart-margin-bottom;
    position: relative;
    color: dimgray;
    background-color: #f7f9fc;
    p {
        font-size: $medium-label-font-size;
        margin-bottom: 0;
        // padding-right: 4px;
    }
    input {
        border: 1px solid $input-border-color;
        font-size: $input-medium-font-size !important;
        height: $input-height !important;
        outline: none !important;
        width: 100%;
        border-radius: 5px;
        box-shadow: 0px 1px 3px -2px rgba(0, 0, 0, 0.6);
        padding-left: 12px;
        &.error {
            border: 1px solid $error-border-color !important;
        }
    }
    input[type="date"]::-webkit-inner-spin-button,
    input[type="date"]::-webkit-calendar-picker-indicator {
        // display: none;
        -webkit-appearance: none;
    }
    input[type="file"] {
        opacity: 0;
        z-index: 100000;
    }
    select {
        border: 1px solid $input-border-color;
        font-size: $input-medium-font-size;
        height: $input-height;
        outline: none;
        width: 100%;
        border-radius: 5px;
        box-shadow: 0px 1px 3px -2px rgba(0, 0, 0, 0.6);
        padding-left: 12px;
        &.error {
            border: 1px solid $error-border-color;
        }
    }
    textarea {
        border: 1px solid $input-border-color;
        font-size: $input-medium-font-size;
        outline: none;
        width: 100%;
        height: $textarea-height;
        border-radius: 5px;
        box-shadow: 0px 1px 3px -2px rgba(0, 0, 0, 0.6);
        padding-left: 12px;
        &.error {
            border: 1px solid $error-border-color;
        }
    }
    &__error {
        position: absolute;
        width: fit-content !important;
        color: $error-color !important;
        left: 100%;
        top: 100%;
        transform: translate(calc(-100% - 8px), calc(-100% - 2px));

        &.offset32 {
            transform: translate(calc(-100% - 40px), calc(-100% - 2px));
        }

        &.offset12 {
            transform: translate(calc(-100% - 20px), calc(-100% - 2px));
            padding-right: 4px;
        }
    }
    .search-wrapper {
        border: 1px solid #cccccc;
        border-radius: 5px;
        background-color: #fff;
        min-height: $input-height;
        height: fit-content;
        position: relative;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        padding: 5px;
        z-index: 1;
        .chip {
            padding: 0px 7px;
            border: 1px solid #95989a;
            background: #fff;
            margin-top: 1px;
            margin-bottom: 1px;
            margin-right: 5px;
            //height: 21px;
            border-radius: 10px;
            display: inline-flex;
            align-items: center;
            font-size: $medium-label-font-size;
            color: #95989a;
            white-space: normal;
        }
    }
    .optionContainer {
        * {
            font-size: 13px;
        }
    }
    .searchBox {
        border: none;
        margin-top: 0;
        position: absolute;
        top: 0;
        color: transparent;
        left: 0;
        background: transparent;
        box-shadow: none !important;
        z-index: -1;
    }
    &__file-input-imitation {
        border: 1px solid #c8c6c7;
        font-size: $medium-label-font-size;
        height: $input-height;
        background-color: #fff;
        outline: none;
        width: 100%;
        border-radius: 5px;
        box-shadow: 0px 1px 3px -2px rgba(0, 0, 0, 0.6);
        padding: 0 12px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;
        &.fixed {
            top: 18px;
            transform: none;
        }

        div {
            position: absolute;
            left: 0;
            top: 0;
            right: 0;
            bottom: 0;

            display: flex;
            flex-direction: row;

            span {
                width: 3rem;
            }
        }
    }

    .rdw-editor-wrapper {
        background: white;
        border: 1px solid #00000033;
        border-radius: 8px;
        box-shadow: 0px 1px 3px -2px #000000aa;
    }

    .rdw-editor-toolbar {
        background: unset;
        border: unset;
        border-bottom: 1px solid #f1f1f1;
    }

    .public-DraftStyleDefault-ltr {
        padding: 0 8px;
        font-size: 13px;
    }
}
