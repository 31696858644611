@import "../color_variables";

.tab-for-pro {
    width: 145px;
    text-align: center;
    font-size: 13px;
    font-weight: 700;
    background-color: $orange_color;
    color: white !important;
    padding: 4px 8px !important;
    border-radius: 5px !important;

    &:hover {
        background-color: #ff6e41;
        color: white;
    }
}

.live-button {
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition-property: -webkit-transform, opacity;
    transition-property: transform, opacity;
}
.navbar {
    padding: 0;

    .header-link {
        color: $menu-color;
        font-size: 13px;
        font-weight: bold;

        &:hover {
            color: #ff6e41;
            transition: color 0.2s linear;
        }
    }
}

.prof-button {
    padding-top: 1.5em;
    justify-content: space-between !important;
}

.prof-confirm-title {
    margin: 0 !important;
}

.logo {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-right: 40px;

    .nav-link {
        padding: 0;
        margin: 0;
    }
    a {
        img {
            min-width: 85px;
            min-height: 70px;
        }
    }
}

.middle_links_togglers {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    padding: 0 24px;
}

.react-autosuggest__container {
    border-left: 2px solid rgba(255, 255, 255, 0.6) !important;
    flex-grow: 100;
    height: 35px;

    input {
        padding-left: 0.8rem !important;
        padding-top: 1px;
        font-size: 0.9rem;
        border: none;
        width: 100%;
        height: 35px;
    }

    .react-autosuggest__suggestions-container {
        background-color: white;
        position: relative;
        z-index: 10;

        ul {
            list-style: none;
            padding: 0;
            // padding-left: 0.8rem !important;
        }
    }
    .react-autosuggest__suggestion--highlighted {
        background-color: #d6d6d6;
    }
    .react-autosuggest__suggestions-container--open {
        ul {
            padding: 0;
            li {
                cursor: pointer;
                padding: 5px 10px;
            }
        }
        li:hover {
            background-color: #d6d6d6;
        }
    }
}

.input-group-append {
    border-right: 2px solid #cccacb;
    margin: -2px;

    button {
        font-size: 0.75rem;
    }
}

.input-group-prepend {
    // margin: -2px;
    height: 35px;
}

.selected-language {
    color: $logo_brown !important;
    text-decoration: underline !important;
    font-weight: normal !important;
}

.header-language {
    font-weight: normal !important;
    padding-top: 0px;
    padding-bottom: 0px;
    padding-left: 6px;
    padding-right: 6px;
}

.header-languages {
    display: flex;
    flex-direction: row;
    padding-right: 1em;
}

.menu-tablet {
    flex-grow: 1;
}

.input-group {
    flex-wrap: nowrap;
}

.header_input {
    max-width: 1088px !important;
}

.admin {
    & .form-control {
        min-width: auto !important;
    }
}

.btn-secondary {
    box-shadow: none;
}

.form-group {
    width: 100%;
}

.mobile-nav {
    &__link {
        color: $second-text-color;
        font-size: 12px;
        &:hover {
            color: #fb6340;
            transition: color 0.3s linear;
        }
    }
}

@media (min-width: 576px) {
    .form-inline .input-group,
    .form-inline .custom-select {
        width: 100%;
    }
}

.collapse-item-mobile {
    margin-left: 57px !important;
}

.input-group-append {
    border-right: 2px solid #cccacb;
    margin: -2px;
}

button {
    span {
        font-size: 1rem;
    }
}

.search_header {
    font-size: 2rem;
    color: #3b3b3b !important;
}

.input-group-append {
    align-content: center;
    display: flex !important;
    .input-group-text {
        padding-top: 0px !important;
        padding-bottom: 0px !important;
    }
}

.input-group-prepend {
    align-content: center;
    .input-group-text {
        padding-top: 0px !important;
        padding-bottom: 0px !important;
    }
}

$triangle_size: 6px;

.dropdown-title {
    padding-right: 10px;
}

.dropdown-toggle {
    &:after {
        display: inline-block;
        width: 0;
        height: 0;
        margin-left: 0.255em;
        vertical-align: 0.1em;
        content: "";
        border-top: $triangle_size solid #5a5a5a;
        border-right: $triangle_size solid;
        border-bottom: 0;
        border-left: $triangle_size solid;
    }
}

.dropdown-content {
    position: absolute !important;
    top: 7px !important;
    left: -15px !important;
}

//tablet_large
@media (max-width: 1199px) {
    .header {
        // min-height: 500px !important;
    }
}
//tablet_small
@media (max-width: 991px) {
    .search_column {
        max-width: 90% !important;
        width: 90% !important;
        margin-bottom: 30px;
    }

    .header {
        padding: 0 !important;
        align-items: flex-end !important;
        // background-image: unset !important;
        // background-color: #ebebeb;
        padding-bottom: 50px !important;
    }

    /*     .body {
        background-color: #ebebeb !important;
    } */

    // .input-group-append {
    //     display: none !important;
    // }
}

//mobile
@media (max-width: 768px) {
    // .tab-for-pro {
    //     width: 120px;
    // }
    .navbar-search,
    .form-control {
        min-width: unset !important;
    }

    .search_column {
        width: 93.6% !important;
        max-width: 93.6% !important;
        margin-bottom: 3.2vw;
        padding: 0;
    }

    .dropdown-content {
        position: absolute !important;
        top: 6px !important;
        left: -10px !important;
    }
}

.navbar_container {
    max-width: 85% !important;
    display: block !important;
    padding: 0;
    margin-top: 20px;

    .main_row {
        margin: 0;
        display: block;
        width: 100%;
    }

    .col {
        display: flex;
        align-items: center;
        padding: 0;
    }

    .navbar-collapse {
        display: inline-flex !important;
        justify-content: flex-end;
        // margin-top: 20px;

        .nav-link {
            margin: 2px 5px;
        }
    }
}

.styles-for-nav-bar-settings {
    display: inline-block !important;
}

.collapse-item-mobile {
    margin-left: 10px !important;
}

.collapse-item-mobile:first-child {
    margin-left: 0 !important;
}

li.border {
    border-color: $search_field_grey !important;
}

.nav-link {
    font-size: 0.9rem;
    font-weight: 600;
}

.h4.nav-link {
    font-size: 1rem;
    font-weight: 700;
}

.h1 {
    margin-right: 1.5rem;
    margin-top: 0.75rem;
}

.drugs_navbar_middle_links {
    margin-left: 24px;
    margin-right: 24px;
    padding: 1rem 0;

    * {
        margin-left: 25px;
        font-size: 1rem !important;
    }
}

.navbar-expand-md .navbar-nav .nav-link {
    padding: 0.1rem;
}

.navbar-search {
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    max-width: 1000px;
}

.header_inner_search_container {
    margin-top: 0 !important;
    padding: 44px 1rem 0px 1rem;
    float: right;

    @media all and (max-width: 768px) {
        // display: flex;
        // align-items: flex-start;
        // margin-top: 20px !important;
        max-width: 100% !important;
        padding: 42px 1rem 0px 1rem;
    }
}

.search_column {
    display: flex;
    flex-direction: column;
    padding: 0;
}

#other_pages_search {
    margin-top: 15px;
    width: calc(100% - 400px);

    & > div {
        width: 100%;
        padding: 0;
        margin: 0;
        margin-left: auto;
        margin-right: auto;
        display: flex;
        justify-content: space-between;
        align-items: center;

        &:first-child {
            margin-bottom: 30px;
        }
    }
}

@media (max-width: 1199px) {
    .logo {
        margin-left: 31px;
        margin-top: 22px;
        margin-right: 20px;
        display: flex;
        flex-direction: column;

        .h1 {
            margin: 0px !important;
        }

        .tablet_languages {
            display: flex;
            margin-top: 15px;
            justify-content: center;
            div {
                display: inline-block;
                padding: 0 !important;
            }
        }
    }

    .navbar_container {
        max-width: 100% !important;
        width: 100% !important;
        padding: 0px !important;

        .navbar-nav {
            flex-direction: column !important;
            align-items: center;
            margin-top: 32px;
            margin-right: 55.2px;
            li {
                margin: 0px !important;
                margin-bottom: 20px !important;
            }
        }
    }
}

@media (max-width: 991px) {
    .tablet_small {
        display: block;

        &.row {
            width: 100%;
        }

        .col {
            display: flex;
            justify-content: center;

            a {
                margin-right: 50px;
                margin-left: 50px;
                font-size: 1.2rem;
            }
        }
    }

    .header_search_container.other {
        .search_header {
            display: none;
        }
    }
}

@media (max-width: 768px) {
    .padding-for-mobile {
        // padding: 0 !important;
    }
    .collapse-item-mobile {
        margin-top: 10px !important;
        margin-left: 0 !important;
    }
    .collapse-item-mobile:first-child {
        margin-top: 15px !important;
    }

    .collapse-btn {
        ul {
            flex-direction: column;
        }
    }

    .collapse-btn {
        width: 90vw !important;
        height: 90vh !important;
        margin: 5vh 5vw;

        &.collapse {
            display: none !important;
        }

        &.show {
            display: flex !important;
        }

        .navbar-nav {
            width: 100%;
            height: 100%;
            margin: 0;
            justify-content: center;
        }
    }
    .react-autosuggest__container {
        height: 32.2px;

        input {
            height: 32.2px;
        }
    }

    .input-group-alternative {
        justify-content: center;
        align-items: center;
        box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    }

    .input-group-prepend {
        height: 30px;

        .input-group-text {
            padding-right: 6px !important;
        }
    }

    #navbar-main {
        padding-top: 0px;

        .main_row {
            & > .col {
                padding: 0;

                .logo {
                    margin-top: 13px;
                    margin-left: 24px;
                }
            }
        }
    }

    .header {
        min-height: fit-content !important;
        padding: 0 !important;

        #navbar-main {
            padding: 0;
        }

        nav {
            position: unset !important;
        }

        .header_search_container .container {
            padding: 0;
            margin-top: 50px;
        }

        .logo {
            display: flex;
            a {
                padding: 0px;
                display: flex;
                justify-content: center;
                align-items: center;
                min-height: 30px;

                @media (max-width: 600px) {
                    min-height: 30px;
                    font-size: 1.12em;
                }
                @media (max-width: 570px) {
                    min-height: 30px;
                    font-size: 0.9em;
                }
                @media (max-width: 500px) {
                    min-height: 25px;
                    font-size: 0.9em;
                    .tab-for-pro {
                        width: 110px;
                    }
                }
                @media (max-width: 400px) {
                    min-height: 25px;
                    font-size: 0.9em;
                    .tab-for-pro {
                        width: 90px;
                    }
                }
            }
        }
    }

    .search_header {
        font-size: 1.5rem;
        white-space: nowrap;
        width: 100%;
    }

    .navbar-collapse {
        width: 100vw !important;
        padding: 0 !important;
        margin: 0 !important;
        border-radius: 0 !important;
        background: #f6fafd !important;
        box-shadow: unset !important;
        top: 112px !important;

        display: flex !important;
        flex-direction: column;
        justify-content: flex-start !important;

        & > * {
            border-bottom: 1px solid #d6d5d6;
            width: 90%;
            padding: 15px 8px !important;
            margin: 0 !important;
        }

        .mobile_menu_langs {
            display: flex;
            justify-content: start;

            a {
                padding: 0 !important;
                margin: 0 !important;
                margin-right: 30px !important;

                &:last-of-type {
                    margin-right: 0 !important;
                }
            }
        }

        a {
            font-size: 1.5rem !important;
            // color: #3a3a3a !important;
            font-weight: 400 !important;
        }

        a.not-active {
            color: #a9a9a9 !important;
        }
    }

    .navbar_container {
        max-width: 100% !important;
        padding: 0;
        margin-top: 10px;
        min-height: 340px;
        @media (max-width: 992px) {
            min-height: 290px;
        }
        @media (max-width: 600px) {
            min-height: 270px;
        }
        @media (max-width: 500px) {
            min-height: 240px;
        }
        @media (max-width: 400px) {
            min-height: 210px;
        }

        .logo {
            margin: 0 !important;
            margin-left: 10px !important;
        }

        #other_pages_search {
            width: calc(77vw - 100px);
        }

        button#navbar-collapse-main {
            position: absolute;
            top: 13px;
            right: 13px;
        }
    }
}

.dropdown-item {
    &.active,
    &:focus {
        background-color: grey;
    }
}

.everywhere-search {
    .search-filter {
        box-shadow: none;
    }
    .input-group-alternative {
        box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    }
    &__text {
        color: $third-text-color !important;
        font-weight: bold;
        font-size: 24px;
        text-align: center;
        width: 100%;
    }
}
