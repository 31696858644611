@import "../color_variables";

.news-block__content {
    width: 97%;
    text-align: justify;
    .news-title {
        color: #722d1d;
        font-weight: 700;
        font-size: 16px;
        padding-bottom: 10px;
    }
    .news-text {
        font-size: 14px;
    }
    .news-date {
        font-weight: 700;
        font-size: 11px !important;
        .link {
            color: #df2d00 !important;
        }
    }
}
.news_block {
    max-width: 100%;

    padding: 25px;

    margin-bottom: 15px;

    display: flex;

    img {
        width: 250px;
        height: 250px;
        margin-right: 25px;
        flex-shrink: 0;
    }

    &__content {
        margin-left: 24px;
        .news-date {
            font-size: 11px !important;
            .link {
                color: #df2d00 !important;
            }
        }
    }
    &:last-child {
        margin-bottom: 0;
    }

    h3 {
        color: $second-text-color !important;
        // font-size: 1rem;
        font-size: 1.2rem;
        font-weight: 900;
        // color: $orange_color;
        a {
            font-size: 1.5rem;
            &:hover {
                text-decoration: underline;
            }
        }
    }

    & > div {
        & > div {
            overflow: hidden;

            & > p {
                text-overflow: ellipsis;
            }
        }
    }

    p {
        // color: #49414C !important;
        // font-size: 1.1rem;
        color: $orange_color;
        font-size: 1rem;
        margin-bottom: 0.5rem;
        span {
            font-weight: 900;
        }
    }

    span {
        font-size: 1.125rem;
        color: $orange_color !important;
    }
}

@media (max-width: 768px) {
    .news_block {
        flex-direction: column;
        padding: 0;

        img {
            margin-right: auto;
            margin-left: auto;
            margin-bottom: 25px;
        }
    }
}
