@import "./../color_variables";

.warnings_page {
    .main-title {
        font-weight: bold;
        color: $black;
        white-space: nowrap;
        font-size: 2rem;
    }
    @media (max-width: 768px) {
        .main-title {
            white-space: normal;
        }
    }
    
    .warnings_body {
        a {
            color: $orange_color;
            &:hover {
                text-decoration: underline;
            }
        }
        .nav-item {
            a {
                background-color: $tab_header_grey;
                margin-right: 6px;
                color: inherit;
            }
    
            .active {
                background-color: $orange_color;
                color: $white;
            }
        }
    }

    .main_content_sda_wrapper {
        display: flex;

        .main_content {
            flex-grow: 1;
            margin-right: 15px;
        }
    }
}

@media (max-width: 768px) {
    .main_content_sda_wrapper {
        flex-direction: column;

        .sda_block {
            margin-left: auto;
            margin-right: auto;
            margin-bottom: 15px;
        }
    }
}