@import "./../color_variables";
.drugs_and_medications_title {
    font-weight: bold;
    color: $second-text-color;
    font-size: 1.2rem;
    line-height: 1.5rem;
}
.popular_requests_container, 
.categories_container{
    font-size: 1rem;
    color: $black;
    
    a {
        color: $orange_color;
        &:hover {
            text-decoration: underline;
        }
    }
    &_in {
        color: $black;
        margin: -2rem 0;
    }

    max-width: 1000px;
}

