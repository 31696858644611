@import "./../color_variables";

.interactions_checker_page {
    .interactions_checker_title {
        font-weight: bold;
        color: $black;
        font-size: 2rem;
    }
    
    .interactions_checker_body {
        font-size: 1.2rem;
        a {
            color: $orange_color;
            &:hover {
                text-decoration: underline;
            }
        }
    
        max-width: 1000px;
    }

    .main_content_sda_wrapper {
        display: flex;
    }
}

@media (max-width: 768px) {
    .main_content_sda_wrapper {
        flex-direction: column;

        .sda_block {
            margin-left: auto;
            margin-right: auto;
            margin-bottom: 15px;
        }
    }
}