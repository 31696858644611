@import "../color_variables";

.main-title-sign-in {
    font-size: 1.5rem;
    color: $black;
    font-weight: bold;
}

.second-level-titles-sign-in {
    font-size: 1rem;
    color: $black;
    font-weight: bold;
}

.sign-in-link {
    color: $blue-button-and-links !important;
    font-weight: bold;
}

.sign-in-link:hover {
    color: $blue-button-and-links-hovered !important;
    cursor: pointer;
    text-decoration: underline !important;
}

.inpusts-sign-in {
    color: $black-form-text;
    width: 40%;
    height: 3rem;
    padding-left: 1rem;
    border: thin solid $gray-form-border;
    background-color: $gray-form-background;
    border-radius: 0.4rem;
}

.reg_button_wrapper {
    display: flex;
    justify-content: center;
}

.reg_button {
    margin-top: 30px;
    margin-bottom: 30px;
    padding: 0.3rem 1rem;
    border-radius: 5px !important;
    background-color: $orange_color !important;
    display: inline-block !important;

    &.nav-link {
        p {
            color: $white !important;
            font-weight: bold;
            font-size: 1rem !important;
            text-align: center !important; 
            padding: 0 !important;
            margin: 0 !important; 
            display: inline-block !important;
        }
    }

    &:hover {
        background-color: $blue-button-and-links-hovered;
        cursor: pointer;
    }
}

.forgot_password_username {
    font-weight: bold;
}

.forgot_password_username:hover {
    cursor: pointer;
    text-decoration: underline !important;
}

.bottom-margin-del {
    padding-bottom: 0;
}

.login_header {
    a {
        color: $orange_color !important;

        &:hover {
            color: $orange_color !important;
        }
    }
}

.login_body {
    a {
        color: $orange_color !important;

        &:hover {
            color: $orange_color !important;
        }
    }

    p {
        text-align: justify;
        font-size: 1.2rem !important;
        font-weight: 400 !important;
    }

    input {
        box-shadow: 0px 3px 6px $search_field_grey;
        width: 416px;
        height: 41px;
        border: solid 1px $search_field_grey;
        border-radius: 7px;
    }

    .row {
        margin-bottom: 30px;
    }

    .form-check-input {
        width: 24px;
        height: 24px;
        margin-left: 0;
        display: inline-block;
    }

    .rememberMe {
        display: inline-block;
        padding: 0;
        margin-left: 43px;
        margin-top: 2px;
    }

    .col-3 {
        padding-left: 0;
    }

    .inpusts-sign-in {
        width: 416px !important;
        max-width: 100% !important;
    }
}

.col-md-4 {
    padding-left: 0;
}

@media screen and (max-width: 800px) {
    .inpusts-sign-in {
        width: 100% !important;
    }
}

@media (max-width: 768px) {
    .reg_button {
        padding: 0.6rem 2rem;
    
        &.nav-link {
            p {
                font-size: 1.5rem !important;
            }
        }
    }
}
