.alphabet_button, .alphabet_button_a, .alphabet_button_custom, .alphabet_button_selected {
  background-color: $nav-pills-bg;
  border: thin solid $nav-link-disabled-color;
  font-weight: bold;
  border-radius: 0.2em;
  padding: 0.2em 0.6em;
}

.alphabet_button_a {
  margin-left: 0;
}

.alphabet_button:hover {
  background-color: $black;
  color: white;
  cursor: pointer;
  border: thin solid $black;
}

.alphabet_button_selected {
  background-color: $black !important;
  color: white;
  cursor: pointer;
  border: thin solid $black !important;
}