@import '../color_variables';

.mainTitle {
  font-size: 1.5em;
  font-weight: bold;
  color: $second-text-color;
}

h1, h2 {
  margin-top: 0;
}