@import "./../color_variables";

.dosages_page_container {
    padding: 2rem;
    .dosages_title {
        font-weight: bold;
        color: $second-text-color;
        font-size: 1.2rem;
        line-height: 1.5rem;
    }

    .dosages_body {
        font-size: 1rem;
        color: $black;

        a {
            color: $orange_color;
            &:hover {
                text-decoration: underline;
            }
        }
        &_in {
            color: $black;
            margin: -2rem 0;
        }

        max-width: 1000px;
    }

    .search_card {
        padding-top: 0;
        padding-bottom: 0;

        .search_field {
            margin-top: 10px;
            margin-bottom: 20px;
        }
    }

    .dosage_container_main_content {
        display: flex;
    }

    .main_content {
        width: 90%;
        margin-left: 0;
        .dosage_news {
            p {
                margin-top: 14px;
                font-size: 14px;
                line-height: 1.2;
            }
            .news-date {
                font-size: 11px !important;
                a {
                    color: #df2d00;
                }
            }
        }
    }

    .main_content_sda_wrapper {
        display: flex;
    }
}

@media (max-width: 768px) {
    .dosages_page_container {
        .dosage_container_main_content {
            display: block;
        }
    }
    .main_content_wrapper {
        flex-direction: column;

        .sda_block {
            margin-left: auto;
            margin-right: auto;
            margin-bottom: 15px;
        }
    }
}
