@import "../components/Drugs/color_variables.scss";
* {
    caret-color: $logo_brown;
}

.form-check-input {
    cursor: pointer;
}

.inlineTooltip-button svg {
    vertical-align: initial;
}

.public-DraftEditorPlaceholder-root {
    background-color: transparent;
}

.admin-tabs-nav {
    width: 100%;
    &.active {
        background-color: #5c6483;
        color: #fff;
    }
    & .nav-item {
        background-color: #e9ecef;
        border: 1px solid #f8f9fe;
        cursor: pointer;
        &:hover {
            background-color: #e4e4e6;
        }
    }
}

.table {
    & td,
    & th {
        font-size: 0.8125rem;
        white-space: inherit;
    }
}

.news-block {
    margin-top: 1.5rem;
    width: 32%;
    background-color: white;
    padding: 1.5rem;
    box-shadow: 0 0 0.3em lightgray;
    float: left;
    margin-right: 0.9rem;
}

.news-block:hover {
    background-color: #f9f9f9;
    box-shadow: 0 0 0.3em #a0a0a0;
    cursor: pointer;
}

.news-block img {
    width: 100%;
    height: 12rem;
    margin-bottom: 0.7rem;
}

.news-title {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.news-title:hover {
    text-decoration: underline;
    cursor: pointer;
}

.news-description {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    height: 6rem;
}

.news-description:hover {
    cursor: pointer;
    text-decoration: underline;
}

.mobileAppImg {
    margin-top: 30px;
    float: left;
}

.mobile-app-desc {
    float: left;
    margin-top: 3rem;
    margin-left: 2rem;
}

.mob-desc-p {
    width: 25rem;
}

.card-header {
    padding-top: 1.5rem;
    padding-left: 1.5rem;
    padding-right: 2.5rem;
}

.mobileAppBlock {
    background-color: #f2f2f2;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    .mobile_app_btn_block {
        padding-bottom: 11px;
    }
    .reg_button_register {
        background-color: $orange_color !important;
        border-radius: 5px;
        padding: 0.3rem 1rem;
        font-size: 1rem !important;
        color: white;
        display: inline-block;
    }
}

.row {
    margin: 0;
}

.content_container {
    padding-top: 100px;
    .row {
        padding-left: 0 !important;
        padding-right: 0 !important;
        margin-left: 0 !important;
        margin-right: 0 !important;
        width: 100%;
        .col {
            width: 100%;
            padding-right: 0px;
            padding-left: 0px;
        }
    }
    .bg-secondary {
        // padding-right: 15px;
        // padding-left: 15px;
        border-radius: 24px;
        background-color: white !important;
        .slider-box {
            background-color: $carousel_mobileApp_color !important;
            border-radius: 24px 24px 0 0;
        }
    }
}

a.news-title {
    display: block;
    text-decoration: none;
    color: #712c1c !important;
    font-size: 16px;
    font-weight: bold;
    line-height: 1.2;
    white-space: pre-wrap;
    /* CSS3 */
    white-space: -moz-pre-wrap;
    /* Mozilla, since 1999 */
    white-space: -pre-wrap;
    /* Opera 4-6 */
    white-space: -o-pre-wrap;
    /* Opera 7 */
    word-wrap: break-word;
    /* Internet Explorer 5.5+ */
    p {
        font-size: 14px;
    }
}

@media screen and (max-width: 800px) {
    .mobileAppImg img {
        height: 16rem;
    }
    .mobile-app-desc {
        margin-top: 1.5rem;
    }
    .mob-desc-p {
        width: 20rem;
    }

}

.tablet_large,
.tablet_small,
.mobile {
    display: none;
}

.desktop {
    display: inline-block;
}
.desktop-flex {
    display: flex;
}

.alphabet_block {
    // margin: -3px;
    margin-left: 0px;
    margin-right: -6px;
    .alphabet_button {
        border: 0;
        display: inline-block;
        margin: 1.5px 3px 1.5px 0;
        width: 2.5rem;
        height: 2.25rem;
        line-height: 2rem;
        border-radius: 4px;
        background-color: #f0f0f0;
        font-weight: bold;
        color: #3b3b3b;
        text-align: center;
        white-space: nowrap;
        &.alphabet_button_selected {
            background-color: black;
            color: white;
        }
    }
    input:hover {
        background-color: $logo_brown;
        color: $white;
        cursor: pointer;
    }
    span {
        background-color: #f0f0f0 !important;
        color: #bbbbbb !important;
        cursor: default !important;
    }
    p {
        font-size: 10rem !important;
        margin-top: 8px;
        margin-left: 5px;
    }
}

//tablet_large
@media (max-width: 1199px) {
    .desktop {
        display: none;
    }
    .desktop-flex {
        display: none;
    }
    .tablet_large {
        display: inline-block;
    }
}

//tablet_small
@media (max-width: 991px) {
    .desktop,
    .tablet_large {
        display: none;
    }
    .tablet_small {
        display: inline-block;
    }
    .content_container {
        .bg-secondary {
            margin-top: 0px;
            border-radius: 24px;
        }
    }
    h1 {
        font-size: 1.5rem !important;
    }
}

//mobile
@media (max-width: 768px) {
    .content_container{
        padding-top: 78px;
    }
    html {
        font-size: 12px;
    }
    .desktop,
    .tablet_large,
    .tablet_small {
        display: none;
    }
    .mobile {
        display: inline-block;
    }
    .bg-secondary {
        border-radius: 0;
        padding-right: 0px !important;
        padding-left: 0px !important;
    }
    .mobileAppBlock.mobile {
        margin: 0 !important;
        padding: 20px 0 40px 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: #ebebeb;
        p {
            color: #49414c !important;
            font-size: 1.4rem !important;
            width: 80vw;
        }
        h1 {
            margin-bottom: 31.5px;
            color: #0e2533;
            font-size: 1.6rem;
            width: 80vw;
        }
        .mobile_app_btn_block {
            margin-top: 40px;
        }
    }
    .card-header {
        border-radius: unset !important;
        padding-top: 2.5rem;
        // h1 {
        //     font-size: 1.5rem !important;
        //     font-weight: 500 !important;
        // }
        // &:first-child {
        //     display: none;
        // }
    }
    .alphabet_block {
        // margin: -2px;
        .alphabet_button {
            margin: 2px;
        }
    }
    .mobileAppBlock {
        .reg_button_register {
            padding: 0.6rem 2rem;
            font-size: 1.5rem !important;
        }
    }
}

.display-block {
    display: block !important;
}

.not-active {
    color: #a9a9a9 !important;
}

.date-label {
    position: absolute;
    color: #7b7b7b !important;
    font-size: 1.25rem;
    padding: 0.625rem 0.75rem;
    background-color: #f1eff0;
    &:focus {
        display: none;
    }
    &:active {
        display: none;
    }
}

.form-control:focus + .date-label {
    display: none;
}

.form-control:active + .date-label {
    display: none;
}

table,
thead,
tbody,
tr,
th,
td {
    border: 1px solid #afb8bd;
}

.btn-secondary {
    border: 1px solid #d1cfd0;
}
