@import "./../color_variables";

.search_field {
    .form-group {
        border: 2px solid rgba(255, 255, 255, 0.6) !important;
    }

    width: 1088px;
    max-width: 90%;
    margin: 38.5px auto;
    .react-autosuggest__container {
        border: none !important;
        height: 100%;
        margin: 0;

        input {
            height: 100%;
            margin: 0;
            border: none !important;
            background: transparent;
        }
    }

    .react-autosuggest__container--open {
        position: absolute;
        width: calc(100% - 52px);
    }

    .search_input_wrapper {
        width: 100%;
    }

    .search_button {
        // border-radius: 0px 8px 8px 0px;
        background-color: $orange_color;
        // margin-top: -2px;
        width: 55px;

        .input-group-text {
            margin-right: auto;
            margin-left: auto;
            padding: 0;

            .fas {
                //width: 36px;
            }
        }

        &:hover {
            background-color: $logo_brown;
        }
    }

    .input-group,
    .search_button {
        //height: 46px;
    }

    input {
        padding-left: 18px;
        font-size: 1.5rem;
        //height: 46px;
    }

    @media (max-width: 768px) {
        .react-autosuggest__container--open {
            top: 0.2rem;
            .input {   
                position: relative;
            }
        }
    }
}
