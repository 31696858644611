@import "./../color_variables";

.pregnancy_page_container {
    padding: 2rem;
    background-color: #fff;
    display: flex;
    border-radius: 24px;
    width: 100%;
    .pregnancy_page {
        width: 90%;
        .pregnancy_title {
            font-weight: bold;
            color: $second-text-color;
            font-size: 20px;
        }

        .pregnancy-subtitle {
            font-weight: bold;
            color: $black;
            font-size: 1.5rem;
        }

        .pregnancy_body {
            font-size: 1rem;
            color: $black;
            padding: 1.5em 1.5rem 1.5rem 0;

            a {
                color: $orange_color;
                &:hover {
                    text-decoration: underline;
                }
            }

            max-width: 1000px;
        }

        .pregnancy_news {
            margin-top: 2rem;
            p {
                margin-top: 14px;
                font-size: 14px;
                line-height: 1.2;
            }
            .news-date {
                font-size: 11px !important;
                a {
                    color: #df2d00;
                }
            }
        }

        .search_card {
            padding-top: 0;
            padding-bottom: 0;

            .search_field {
                margin-top: 10px;
                margin-bottom: 20px;
            }
        }

        .main_content {
            width: 100%;
        }

        .main_content_sda_wrapper {
            display: flex;
        }

        .alphabet-block-pregnancy {
            width: calc(67% - 25px);
            .alphabet_button {
                &:nth-last-child(2) {
                    width: 4rem;
                }
                &:last-child {
                    width: 13rem;
                }
            }
        }
        @media (max-width: 992px) {
            .alphabet-block-pregnancy {
                width: calc(100% - 11px);
            }
        }
    }
}
@media (max-width: 768px) {
    .pregnancy_page_container {
        display: block;
    }
    .main_content_sda_wrapper {
        flex-direction: column;

        .sda_block {
            margin-left: auto;
            margin-right: auto;
            margin-bottom: 15px;
        }
    }
}
