@import "./../color_variables";

.footer {
    display: flex;
    justify-content: center;
    background-color: #fff;
    // max-width: 87.5% !important;
    padding-left: 0px;
    padding-right: 0px;

    @media (max-width: 991px) {
        padding: 16px;
    }
    &_container {
        // max-width: 87.5% !important;
        padding: 0px 20px;
    }
    &_row {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .non-centered {
        justify-content: left;
    }

    .logo_block {
        display: flex;
        flex-direction: column;
        margin-right: 120px;
        img.logo_img {
            margin-bottom: 45px;
        }
        a,
        span {
            font-size: 0.9rem;
            color: #49414c;
        }
        a:hover {
            text-decoration: underline;
        }
        & > div {
            display: flex;
        }
        .contacts_block_wrapper {
            margin-bottom: 20px;
        }
        .contacts_block {
            display: flex;
            flex-direction: column;
            margin-left: 30px;
        }
    }
    .copyright_block {
        margin-top: 30px;
        display: flex;
        align-items: center;
        .copyright_text_block {
            display: flex;
            flex-direction: column;
        }
        .copyright_text {
            margin-left: 20px;
            margin-top: 40px;
            color: #3fa9c0 !important;
        }
    }
    .brands_and_links {
        display: flex;
        flex-direction: column;

        .brands_block {
            display: flex;
            flex-wrap: wrap;
            width: 100%;
            & > div {
                display: flex;
                flex-direction: column;
                margin-right: 100px;
                &:last-of-type {
                    margin-right: 0;
                }
            }
            .sn {
                width: 150px;
                padding-top: 10px;
                margin-bottom: 50px;
            }
            .isim {
                width: 120px;
                height: 40px;
                margin-bottom: 50px;
                margin-top: 45px;
            }
            .em {
                width: 120px;
                height: 90px;
                margin-bottom: 50px;
            }
            .undp {
                margin-top: -15px;
                width: 90px;
                height: 125px;
                margin-bottom: 50px;
            }
        }
        .links_block {
            display: flex;
            flex-wrap: wrap;
            h3 {
                color: $second-text-color !important;
                font-size: 0.9rem;
                margin-bottom: 20px;
            }
            & > div {
                display: flex;
                flex-direction: column;
                margin-right: 100px;
                &:last-of-type {
                    margin-right: 0;
                }
            }
            a {
                margin-bottom: 12px;
                font-size: 0.8rem;
                color: #49414c;
                cursor: pointer;
                &:hover {
                    text-decoration: underline;
                }
            }
        }
        .copyright_block.desktop {
            display: flex;
        }
    }

    .copyright_block.mobile {
        display: none;
    }
}

@media (max-width: 768px) {
    .footer {
        flex-wrap: wrap;
        justify-content: flex-start;
        .logo_block {
            margin-right: 0;
            img {
                width: 106.8px;
                height: 87px;
            }
        }
        &_row {
            display: block;
        }
        .links_block {
            flex-direction: column;
            margin: 30px 0;
        }
        .copyright_block.desktop {
            display: none;
        }
        .copyright_block.mobile {
            display: flex;
        }
        .logo_block {
            img.logo_img {
                margin-bottom: 22.5px;
            }
            .contacts_block_wrapper {
                margin-bottom: 10px;
            }
        }
        .copyright_block {
            margin-top: 15px;
        }
        .links_block {
            margin-top: 15px;
            h3 {
                margin-bottom: 10px;
            }
            a {
                margin-bottom: 6px;
            }
        }
    }
}

@media (max-width: 640px) {
    .brands_block {
        & > div {
            margin-right: 34px !important;
            &:last-of-type {
                margin-right: 0;
            }
        }
        .sn {
            width: 90px !important;
            height: 70px !important;
            margin-bottom: 50px;
        }
        .isim {
            width: 100px !important;
            height: 32px !important;
            margin-bottom: 50px;
            margin-top: 35px;
        }
        .em {
            width: 90px !important;
            height: 65px !important;
            margin-bottom: 50px;
        }
        .undp {
            width: 36px !important;
            height: 74px !important;
            margin-bottom: 50px;
        }
    }
}

@media (max-width: 415px) {
    .brands_block {
        margin-top: 20px;
        & > div {
            margin-right: 16px !important;
            &:last-of-type {
                margin-right: 0;
            }
        }
        .sn {
            width: calc(27vw - 32px) !important;
            height: calc(311 * (27vw - 32px) / 405) !important;
            margin-bottom: 25px !important;
        }
        .isim {
            width: calc(33vw - 32px) !important;
            height: calc(224 * (33vw - 32px) / 667) !important;
            margin-bottom: 25px !important;
            margin-top: 25px !important;
        }
        .em {
            width: calc(30vw - 32px) !important;
            height: calc(149 * (30vw - 32px) / 200) !important;
            margin-bottom: 25px !important;
        }
        .undp {
            width: calc(18vw - 32px) !important;
            height: calc(350 * (18vw - 32px) / 178) !important;
            margin-bottom: 25px !important;
        }
    }
    .non-centered {
        display: none;
    }
    .footer {
        &_row {
            padding-bottom: 0;
        }
    }
}
