@import "./../color_variables";
/*News Main cards*/
.news-main {
    background-color: #f2f2f2;
    padding: 30px 50px;

    &__content {
        padding: 0.7rem;

        &__title {
            color: $menu-color;
            &:hover {
                color: $orange_color;
            }
        }
    }
}

.card-img-top {
    width: 100%;
    height: 15vw;
    object-fit: cover;
}

.card-body {
    padding: 0.5rem !important;
}
/*last news main all news */

.last-news-main {
    padding: 16px;
    background-color: #fff;
    display: flex;
    width: fit-content;

    // &__news {
    //     padding-right: 50px;
    // }
    &__news-block {
        margin-right: 28px;
        display: flex;
        flex-basis: 100%;
        margin-bottom: 32px;
        img {
            width: 214px;
            height: 155px;
            object-fit: cover;
        }
        &__content {
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
            justify-content: space-between;
            text-align: justify;

            p {
                margin-top: 14px;
                font-size: 14px;
                line-height: 1.2;
                &.news-date {
                    font-size: 11px !important;
                    a {
                        color: #df2d00;
                    }
                }
            }
        }
        &:last-child {
            margin-bottom: 0;
        }
    }
    .top-ad {
        border: 1px solid #707070;
        height: 300px;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 14px;
        text-align: center;
    }
    .information {
        margin-top: 32px;
        h3 {
            color: #ff6e41 !important;
        }
        a,
        span {
            display: block;
            font-size: 13px;
            line-height: 2;
        }
        a {
            color: $second-text-color !important;
            &:hover {
                color: #ff6e41 !important;
            }
            &:active {
                color: #ff6e41 !important;
            }
        }
    }
    .bottom-ad {
        margin-top: 24px;
    }
}

@media (max-width: 768px) {
    .last-news-main {
        display: flex;
        align-items: center;
        flex-direction: column;
    }

    .card {
        margin-right: 0px !important;
    }
}

@media (max-width: 576px) {
    .card-img-top {
        height: 48vw;
    }
}
