@import "../color_variables";
.drug-details_container {
    p,
    tr,
    td {
        font-size: 1rem !important;
    }
}
p,
h2,
h3,
h4,
h5,
tr,
td {
    // font-family: Tahoma;
    color: $black !important;
}

.underlined {
    text-decoration: underline;
}

.level-3-hierarchy {
    color: darkblue !important;
}

.table_margin_bottom {
    margin-bottom: 1rem;
}

h2 {
    // color: $blue-button-and-links-hovered !important;
    color: $orange_color !important;
}

p {
    color: dimgray;
}

.drug-details_container {
    .drug-details_top {
        display: flex;
        .drug-details_body {
            width: 90%;
        }
    }
}

.drug-details_main_container {
    display: flex;
    .drug-details_content {
        width: 90%;
        .main_content {
            margin-left: 0;
            .news_title {
                padding-left: 15px;
            }
        }
    }
}
@media (max-width: 768px) {
    .drug-details_main_container {
        display: block;
    }
}

.header_block {
    display: flex;
    padding: 0 1.5rem;
    margin-bottom: 0;
}

.header_block p {
    font-weight: bold;
    color: $black;
    margin: 0;
}

.header_block a {
    color: $blue-button-and-links;
}

.drug-details {
    display: flex;
    flex-direction: column;

    &_text-block {
        width: 100%;
    }
}

.drug-image {
    width: 55%;
    justify-content: center;
    margin: 1.5rem;

    div {
        height: 12rem;
        background-color: rgba($color: $orange_color, $alpha: 0.2);
        border: 1px solid rgba($color: $orange_color, $alpha: 0.3);
        text-align: center;
        line-height: 12rem;
        vertical-align: middle;
    }
    img {
        height: 12rem;
        object-fit: cover;
    }
}

.drug_name {
    font-size: 1.5em !important;
    color: $second-text-color;
    font-weight: bold;
    padding-left: 0;
    a {
        font-weight: normal;
    }
}

.drug_code,
.active_substance,
.tags {
    font-size: 0.9em;

    a {
        font-size: 0.9em;
        color: $second-text-color !important;
        text-decoration: none !important;
        cursor: default !important;
    }
}

h2 {
    margin-top: 0.5em;
    color: $black;
    font-size: 1.4em;
}

.content-block {
    margin-top: 2rem;
    p {
        color: $gray-form-text;
        font-size: 0.9em;
    }
}

.pointer {
    cursor: pointer;
}

@media all and (max-width: 660px) {
    .header_block {
        display: block;
    }
    .drug-image {
        width: 70%;
        margin-top: 15px;
    }

    .drug-tabs {
        margin-top: 20px;
    }
}

.color-blue {
    color: $blue-button-and-links !important;
}

.active-tab {
    //background-color: $blue-button-and-links !important;
    background-color: $orange_color !important;
    color: $white !important;
}

.color-blue:hover {
    color: $blue-button-and-links-hovered !important;
    text-decoration: underline !important;
}
