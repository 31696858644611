.vd-button {
    border: none;
    border-radius: 5px;
    text-align: center;
    width: 100%;
    font-size: 12px;
    font-weight: bold;
    margin-right: 15px;
    margin-bottom: 15px;
    padding: 8px;
    &:last-child {
        margin-right: 0;
        margin-bottom: 0;
    }
    &:focus {
        outline: none;
    }
    &_success {
        background-color: #7eccc4;
        color: #fff;
        &:hover {
            background-color: #56948e;
        }
    }
    &_primary {
        background-color: #039CE3;
        color: #fff;
        &:hover {
            background-color: #058bc9;
        }
    }
}

.vd-rounded-button {
    padding: 9px;
    border-radius: 50%;
    display: inline-flex;
    margin-right: 7px;
    cursor: pointer;
    &:last-child {
        margin-right: 0;
    }
    &_primary {
        background-color: #ffe64e;
        color: #707070;
        &:hover {
            background-color: #efd74a;
        }
    }
}

.vd-dropdown {
    width: 100%;
    margin-top: 15px;
    &__title {
        height: 52px;
        background-color: #eef2f8;
        padding: 0 30px;
        display: flex;
        align-items: center;
        font-size: 20px;
        font-weight: bold;
        justify-content: space-between;
        cursor: default;
        &:hover {
            background-color: #e7eaef;
        }
        p {
            margin-bottom: 0 !important;
        }
    }
    &__drop {
        cursor: pointer;
    }
    &__item {
        padding: 40px 30px 60px 30px;
        font-size: 15px;
        overflow: hidden;
        animation: smoothDrop 1s linear forwards;
        &_title {
            word-spacing: 5px;
            border-bottom: 1px solid #868686;
            margin-bottom: 0;
            // height: 35px;
            // line-height: 35px;
            padding: 5px;
            cursor: pointer;
        }
        &_title.item-selected {
            font-weight: bold;
        }
        &_text {
            padding: 30px;
            animation: smoothDrop 1s linear forwards;

            @media screen and (max-width: 440px) {
                padding: 16px;
            }
        }
    }
    &:first-child {
        margin-top: 0;
    }
}

@keyframes smoothDrop {
    from {
        opacity: 0;
        visibility: hidden;
    }
    to {
        opacity: 1;
        visibility: visible;
    }
}