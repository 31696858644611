@import "color_variables";

html {
    * {
        font-family: Arial, Helvetica, sans-serif !important;
    }
}

body {
    background-color: #f2f2f2;
}

.container {
    max-width: 100%;
}

.content_container {
    max-width: 87.5% !important;
    padding-left: 0px;
    padding-right: 0px;

    @media all and (max-width: 600px) {
        max-width: 100% !important;
    }
}

.first-level-title {
    font-size: 1.5rem;
    color: $black;
    font-weight: bold;
}

.second-level-title {
    font-size: 1.2rem;
    color: $black;
    font-weight: bold;
}

.active-tab-style,
.inactive-tab-style:hover {
    background-color: #ffdfb4 !important;
    color: $black !important;
    font-size: 1rem !important;
    font-weight: normal !important;
    padding-left: 17px !important;
    padding-right: 17px !important;
    padding-top: 7px !important;
    padding-bottom: 6px !important;
    margin-right: 8px !important;
}

.inactive-tab-style {
    background-color: #f1eff0;
    color: $black !important;
    font-size: 1rem !important;
    font-weight: normal !important;
    padding-left: 17px !important;
    padding-right: 17px !important;
    padding-top: 7px !important;
    padding-bottom: 6px !important;
    margin-right: 8px !important;
}

.input-field {
    color: $black-form-text;
    width: 40%;
    height: 3rem;
    padding: 0 1rem;
    border: thin solid $gray-form-border;
    background-color: $white;
    border-radius: 0.4rem;
}

.linked-title {
    font-weight: bold;
    color: $blue-button-and-links !important;
}

.linked-title:hover {
    color: $blue-button-and-links-hovered !important;
    text-decoration: underline !important;
    cursor: pointer;
}

.blue-button {
    width: 15rem;
    height: 3rem;
    background-color: $blue-button-and-links;
    border-radius: 0.4em;
    display: flex;
    margin-bottom: 1rem;
}

.blue-button p {
    margin: auto;
    color: $white;
    font-weight: bold;
    font-size: 1.2rem;
}

.blue-button:hover {
    background-color: $blue-button-and-links-hovered;
    cursor: pointer;
}

.page-block {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-flow: row;

    .page-button {
        background-color: $search_field_grey;
        border-radius: 5px;
        padding: 0.3rem 0;
        width: 2.5rem;
        text-align: center;
        font-size: 1rem !important;
        color: $black-form-text;
        margin-right: 2px;
        margin-left: 2px;
        display: inline-block;
    }

    .page-current {
        background-color: $lightgray-form-text;
    }

    .page-skip {
        padding: 0.3rem 0;
        width: 2.5rem;
        text-align: center;
        font-size: 1rem !important;
        color: $black-form-text;
        margin-right: 2px;
        margin-left: 2px;
        display: inline-block;
    }

    .page-button:hover {
        background-color: $gray-form-text;
        color: white;
        cursor: pointer;
    }
}

.loading-search-results {
    width: 100%;
    padding-top: 2rem;
    min-height: 20vh;
}

.nav-tabs-custom {
    border-bottom: 2px solid $orange_color;
    padding-bottom: 0px;

    a {
        color: $orange_color;
        &:hover {
            text-decoration: underline;
        }
    }

    .nav-link {
        font-family: Arial, Helvetica, sans-serif;
        color: black;
        font-weight: normal;
        border: none;
    }
    // .nav-link:hover {
    //     font-family: Arial, Helvetica, sans-serif;
    //     color: black;
    //     font-weight: normal;
    //     border: 1px solid transparent;
    //     border-top-left-radius: 0.375rem;
    //     border-top-right-radius: 0.375rem;
    // }

    .nav-item {
        margin: 0;
        a {
            background-color: $tab_header_grey;
            margin-right: 2px;
            padding: 10px 8px;
            line-height: 1;
            white-space: nowrap;
        }

        .active {
            background-color: $orange_color;
            color: $white;
        }
    }
}

.drugs-button {
    background-color: $orange_color;
    border-color: $orange_color;
    color: white;

    &:checked,
    &:active,
    &:hover,
    &:focus,
    &:target {
        background-color: $orange_color !important;
        border-color: $orange_color !important;
    }
}
.drugs-button.active {
    background-color: $logo_brown !important;
    border-color: $logo_brown !important;
}

.drugs-header {
    margin: 0;
    font-size: 1.25rem;
    font-weight: bold;
    color: $second-text-color !important;
    padding-right: 1.5rem;
}

.drugs-description {
    padding: 1.5em 1.5rem 1.5rem 0;
    font-size: 1rem;
    color: rgba(0, 0, 0, 0.7);
}

@media (max-width: 992px) {
    .nav-link {
        font-size: 0.74rem;
    }
}

@media (max-width: 768px) {
    .nav-tabs-custom {
        border-bottom: unset;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        .nav-item {
            margin: 0.2rem;
        }
        .nav-link {
            border-radius: 0.375rem;
        }
    }
}

@media (max-width: 468px) {
    .input-field {
        width: 100%;
    }
}
