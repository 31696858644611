@import "../color_variables";

.main-block-centering {
    justify-content: center;
}

.main-title-search-results {
    font-size: 1.5rem;
    font-weight: bold;
    color: $black;
}

.drug-name,
.drug-name-others {
    font-weight: bold;
}

.drug-name-others {
    margin-top: 1rem;
    padding-top: 1rem;
    border-top: 1px solid $gray-form-border;
}

.link {
    color: $orange_color !important;
}

.link:hover {
    color: $logo_brown !important;
    cursor: pointer;
}

.text {
    color: $black-form-text;
    font-size: 0.9rem;
}

.links-block {
    display: flex;
}

.links-block a {
    display: block;
}

.first-links-list {
    margin-right: 15rem;
}

.links-block {
    font-size: 0.9rem !important;
}

.shortened-text {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
}

@media all and (max-width: 530px) {
    .links-block {
        display: block;
    }
}
