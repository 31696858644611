@import "../color_variables";

.description-title {
    font-size: 1rem;
    color: $gray-form-text;
    margin-top: 0.5rem;
}

.drugs-by-letter {
    padding: 2rem;
}

.letter-bold {
    font-weight: bold;
}

.drugNameContent {
    color: $blue-button-and-links !important;
    font-weight: bold;
    font-size: 1.1rem;
    cursor: pointer;
}

.drugNameContent:hover {
    color: $blue-button-and-links-hovered !important;
}

.resultsTitle {
    font-size: 1.3rem;
    margin-top: 3rem;
    font-weight: bold;
    color: $black;
}

.search_result_link {
    display: block;
    color: $orange_color !important;
    cursor: pointer;

    &:hover {
        text-decoration: underline !important;
    }
}

.alphabet_block_by_letter {
    width: calc(70% - 25px);
    padding-top: 2rem;
    .alphabet_button {
        &:nth-last-child(2) {
            width: 4rem;
        }
        &:last-child {
            width: 13rem;
        }
    }
    .live-button {
        -webkit-transition-duration: 0.3s;
        transition-duration: 0.3s;
        -webkit-transition-property: -webkit-transform, opacity;
        transition-property: transform, opacity;
    }
}

@media (max-width: 991px) {
    .alphabet_block_by_letter {
        width: calc(100% - 11px);
    }
}
