.dialogs-block {
    background-color: #f8f9fd;
    border-radius: 5px;
    .modal-header {
        border-bottom: unset;
        padding: 5px 10px;
    }
    .bolt {
        font-size: 16px;
        font-weight: bold;
        color: #3b3b3b;
    }
    .modal-body {
        background-color: unset;
        font-size: 16px;
        font-weight: bold;
        color: #3b3b3b;
    }
    .modal-footer {
        border-top: unset;
        button {
            min-width: 90px;
            margin-top: 14px;
            &:nth-of-type(2) {
                background-color: #f77751 !important;
            }
        }
        padding: 0 0 20px 0;
        justify-content: center;
    }
    .dialog-warning {
        margin-top: 20px;
        font-size: 11px;
    }
}

@media (max-width: 768px) {
    .dialogs-block {
        &__content {
            max-width: 100%;
            margin: 0 auto;
        }
    }
}
