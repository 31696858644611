@import "../color_variables";

.account {
    &_drugs-card {
        margin-bottom: 10px;
        &:last-of-type {
            margin-bottom: 0;
        }
    }
}

.fa-times {
    cursor: pointer;
}

.modal-header {
    display: block !important;
}

label {
    // font-family: Tahoma !important;
}

.user-info-block,
.help-report-errors-block {
    padding: 1rem;
    padding-top: 1.5rem;
    background-color: #ffdfb4;
}

.user-info-block {
    // margin-top: -16px;
}

.help-report-errors-block {
    border-top: none;
    margin-bottom: 2rem;
    margin-top: 1rem;
}

.user-info-block p,
.help-report-errors-block p {
    margin: 0;
    font-weight: bold;
    font-size: 1rem !important;
    line-height: 1.6rem !important;
    color: #3b3b3b !important;
}

.help-report-errors-block p {
    font-weight: normal !important;
}

.user-info-block p {
    margin-top: 6px;
}

.user-info-block p:first-child {
    margin-top: 0;
}

.help-report-errors-block p {
    font-weight: normal;
    line-height: 1.4rem !important;
    padding-bottom: 8px;
}

.send-again-span {
    color: #df2d00 !important;
    cursor: pointer;
}

.user-info-block p span {
    font-weight: normal;
}

.account-content {
    margin-top: 1rem;
}

.checkbox-label {
    margin-left: 0.5rem;
    margin-bottom: 1rem;
}

.overview-block {
    margin-top: 1.5rem;
}

.overview-block-item {
    width: 40%;
    margin-right: 10%;
    float: left;
    margin-top: 1rem;
    padding: 0 4px;
}

.overview-block-item a {
    color: #49414c !important;
}

.overview-block-item:hover {
    cursor: pointer;
    text-decoration: underline;
}

.overview-block-title {
    font-size: 1.2rem;
    color: $second-text-color;
    font-weight: bold;
    line-height: 1.5rem;
    min-height: 3rem;
}

.overview-block-desc {
    font-size: 1rem !important;
    color: #49414c !important;
    line-height: 1.2rem;
}

.overview-block-item:nth-child(3) {
    margin-right: 0 !important;
}

.overview-block-item:nth-child(4),
.overview-block-item:nth-child(5) {
    margin-top: 3rem !important;
}

.overview-block .second-level-title {
    color: $blue-button-and-links;
}

.overview-block .second-level-title:hover {
    color: $blue-button-and-links-hovered;
    text-decoration: underline;
    cursor: pointer;
}

.blue-button p {
    color: white !important;
}

.abune-block p,
.abune-block label {
    color: #3b3b3b !important;
    font-size: 1rem !important;
}

.abune-radios {
    margin-left: 3rem;
}

.abune-radios label {
    margin-bottom: 1rem;
    margin-left: 10px;
}

.abune-radios input {
    padding-top: 10px !important;
}

.add_drug_button {
    margin: 30px 0;
    padding: 0.2rem 1rem;
    border-radius: 5px !important;
    background-color: $orange_color !important;
    display: inline-block !important;
    cursor: pointer;

    p {
        color: white !important;
        font-size: 1rem !important;
        text-align: center !important;
        margin: 0 !important;
    }

    &:hover {
        background-color: $orange_hover !important;
    }
}

.abune_button {
    margin-top: 50px;
    margin-bottom: 30px;
    padding: 0.2rem 1rem;
    border-radius: 5px !important;
    background-color: $orange_color !important;
    display: inline-block !important;
    cursor: pointer;
    margin-left: 30%;
}

.abune_button:hover {
    background-color: $orange_hover !important;
}

.abune_button p {
    color: white !important;
    font-size: 1rem !important;
    text-align: center !important;
    margin: 0 !important;
}

.forgot-link {
    margin-bottom: 12px;
}

.forgot-link a {
    color: #eb673f !important;
}

.forgot-link a:hover {
    text-decoration: underline !important;
}

.user-info-block p,
.help-report-errors-block p {
    margin: 0;
    font-weight: bold;
    font-size: 1rem !important;
    line-height: 1.6rem !important;
    color: #3b3b3b !important;
}

.tab-edit-personal p {
    font-size: 1rem !important;
    line-height: 1.6rem !important;
    color: #3b3b3b !important;
}

.tab-edit-personal h4 {
    color: white !important;
    font-weight: normal !important;
}
input::-webkit-calendar-picker-indicator {
    cursor: pointer;
}
.point {
    cursor: pointer;
}
.tab-edit-personal {
    //   select {
    //   // -webkit-appearance: none;
    //   // -moz-appearance: none;
    //   // text-indent: 1px;
    //   // text-overflow: '';
    //   // background: url('../../../assets/img/arrow-down.svg');
    //   // background-repeat: no-repeat;
    //   // background-size: 10px 10px;
    //   // background-position: 94% 50%;
    // }

    select {
        -webkit-appearance: none;
        -moz-appearance: none;
        background-image: linear-gradient(45deg, transparent 50%, gray 50%),
            linear-gradient(135deg, gray 50%, transparent 50%);
        // radial-gradient(#ddd 70%, transparent 72%);
        background-position: calc(100% - 28px) calc(1em + 5px),
            calc(100% - 23px) calc(1em + 5px), calc(100% - 0.5em) 0.5em;
        background-size: 5px 5px, 5px 5px, 1.5em 1.5em;
        background-repeat: no-repeat;
    }
    @media (max-width: 768px) {
        select {
            background-position: calc(100% - 23px) calc(1em + 5px),
                calc(100% - 18px) calc(1em + 5px), calc(100% - 0.5em) 0.5em;
        }
    }
}
