@import "../color_variables";

.register_choose_type {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;

    p {
        margin-right: 50px;

        &:last-of-type {
            margin-right: 0px;
        }
    }
}

.main-title-sign-up {
    font-size: 1.5rem;
    color: $black;
    font-weight: bold;
}

.content-text-sign-up {
    font-size: 0.9rem;
}

.second-level-titles {
    font-size: 1rem;
    color: $black;
    font-weight: bold;
}

.sign-in-link {
    color: $blue-button-and-links !important;
    font-weight: bold;
}

.sign-in-link:hover {
    color: $blue-button-and-links-hovered !important;
    cursor: pointer;
    text-decoration: underline !important;
}

.inpusts-sign-in {
    color: $black-form-text;
    width: 40%;
    height: 3rem;
    padding-left: 1rem;
    border: thin solid $gray-form-border;
    background-color: $gray-form-background;
    border-radius: 0.4rem;
}

.selectors-sign-up {
    width: 40%;
    height: 3rem;
    color: $black-form-text;
    background-color: $gray-form-background;
    border: thin solid $gray-form-border;
    cursor: pointer;
    padding-left: 0.6em;
    margin-bottom: 1rem;
}

.checkboxes-sign-up {
    margin-right: 0.5rem;
}

.subscribing {
    margin-top: 1rem;
}

.margin_for_last_checkbox {
    margin-top: 1rem;
    font-size: 1rem;
    margin-bottom: 1rem;
}

.weak-pass-style,
.strong-pass-style {
    margin-left: 1rem;
}

.weak-pass-style {
    color: red;
    font-weight: bold;
}

.strong-pass-style {
    color: green;
    font-weight: bold;
}

.password-block {
    margin-bottom: 0.6rem;
}

.register_header {
    h3 {
        font-weight: bold;
        color: $black;
        white-space: nowrap;
        font-size: 2rem;
    }
}

.register_body {
    p {
        font-size: 1rem !important;
    }

    .row {
        margin-bottom: 70.5px;
    }
    .button {
        background-color: #ebebeb;
        border-radius: 5px;
        text-align: center;
        padding: 0.3rem 1rem;
        cursor: pointer;

        &:hover {
            background-color: $orange_color;
            color: $white !important;
        }
    }


    .login_body {
        a {
            color: $orange_color !important;

            &:hover {
                color: $orange_color !important;
            }
        }

        p {
            text-align: justify;
            font-size: 1.2rem !important;
            font-weight: 400 !important;
        }

        .row {
            margin-bottom: 30px;
        }

        .form-check-input {
            width: 24px;
            height: 24px;
            margin-left: 0;
            display: inline-block;
        }
    }
}

.register_form {
    a {
        color: $orange_color !important;

        &:hover {
            color: $orange_color !important;
        }
    }

    p {
        text-align: justify;
        font-size: 1.2rem !important;
        font-weight: 400 !important;
    }

    input,
    select {
        box-shadow: 0px 3px 6px $search_field_grey;
        width: 416px;
        max-width: 100%;
        height: 41px;
        border: solid 1px $search_field_grey;
        border-radius: 7px;
    }

    .dropdown {
        & > button {
            width: 100%;
            height: 100%;
            box-shadow: 0px 3px 6px $search_field_grey;
            width: 416px;
            max-width: 100%;
            height: 41px;
            border: solid 1px $search_field_grey;
            border-radius: 7px;
            margin-right: 0;
            background-color: white;
            color: #8898aa;
            font-size: 0.875rem;
            font-weight: 400;
            text-align: left;
            padding: 0.625rem 0.75rem;

            &:focus {
                color: #8898aa;
            }
        }

        & > div {
            width: 100%;
        }

        .dropdown-toggle:after {
            border: none;
            width: 0;  
            height: 0; 

            border-left: 6px solid transparent;
            border-right: 6px solid transparent;
            
            border-top: 6px solid #5a5a5a;
            float: right;
            margin-top: 5px;
            text-align: left;
        }
    }

    .row {
        margin-bottom: 30px;
    }

    .send_notifications {
        display: inline-block;
        padding: 0;
        margin: 0;
    }

    .form-check-input {
        width: 24px;
        height: 24px;
        margin: 0;
        display: inline-block;
        position: unset;
        margin-right: 15px;
    }

    .checkbox_row {
        display: flex;
        align-items: center;
    }
}

.register_submit_button {
    margin-top: 30px;
    margin-bottom: 30px;
    margin-left: auto;
    margin-right: auto;
    padding: 0.3rem 1rem;
    border-radius: 5px !important;
    background-color: $orange_color !important;
    display: block;

    color: $white !important;
    font-weight: bold;
    font-size: 1rem !important;
    text-align: center !important;

    &:hover {
        background-color: $blue-button-and-links-hovered;
        cursor: pointer;
    }

    border-color: $orange_color;
}

@media screen and (max-width: 800px) {
    .isMedicalWorker label {
        margin-top: 3px;
        margin-left: 5px;
    }
}

@media (max-width: 768px) {
    .register_body {
        .row {
            margin-bottom: 35px !important;
            white-space: nowrap;

            .col {
                p {
                    font-size: 1.2rem !important;
                }
            }
        }
    }

    .register_choose_type {
        flex-direction: column;

        p {
            line-height: 1;
            margin-right: 0 !important;
            margin-bottom: 35px;
            padding: 1rem 2rem;
            height: unset !important;
            width: unset !important;
            white-space: nowrap;
        }
    }

    .register_form {
        .dropdown {
            width: 100%;

            button {
                font-size: 1.25rem;
            }
        }

        input,
        select, .dropdown > button {
            box-shadow: unset;
            border: unset;
            border-radius: 2px;
            background-color: #f1eff0; 
            font-size: 1.25rem;
            width: 100%;
            color: #7b7b7b !important;

            &:focus {
                background-color: #f1eff0 !important;
            }
        }  

        input {
            &::placeholder {
                color: #7b7b7b !important;
            }
        }
    }

    .register_submit_button {
        padding: 0.6rem 2rem;
        font-size: 1.5rem;
    }

    .register_body {
        .button {
            padding: 0.6rem 2rem;
            font-size: 1.5rem !important;
        }
    }
}
