.contacts {
    max-width: 100%;
    width: 1020px;
    margin: 0 auto;

    &__info {
        padding: 20px 25px;
        max-width: 100%;
        width: 600px;
    }

    &__header {
        color: #743125;
    }
    &__content {
        line-height: 2.2;
        margin-bottom: 15px;
        font-size: 15px;
    }
    &__icon {
        margin: 0 13px 0 0;
    }
}

@media (max-width: 768px) {
    .contacts {
        padding: 1rem;
        display: block;
    }
}
