@import "./../color_variables";

.conditions_and_diseases_page {
    .conditions_and_diseases_title {
        font-weight: bold;
        color: $black;
        font-size: 2rem;
    }
    
    .conditions_and_diseases_body {
        font-size: 1.2rem;
        color: $black;
    
        a {
            color: $orange_color;
            &:hover {
                text-decoration: underline;
            }
        }
    
        max-width: 1000px;
    }
    
    .search_card {
        padding-top: 0;
        padding-bottom: 0;
    
        .search_field {
            margin-top: 20px;
            margin-bottom: 40px;
        }
    }
    
    .alphabet_card {
        max-width: 624px;

        .alphabet_button {
            &:nth-last-child(2) {
                width: 4rem;
            }
    
            &:last-child {
                width: 13rem;
                font-size: 1rem !important;
            }
        }
    }

    
    .main_content_sda_wrapper {
        display: flex;
    }
}

@media (max-width: 768px) {
    .main_content_sda_wrapper {
        flex-direction: column;

        .sda_block {
            margin-left: auto;
            margin-right: auto;
            margin-bottom: 15px;
        }
    }
}