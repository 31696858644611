@import "./../color_variables";

.drug_briefing {
    padding: 16px 0px;
    h3 {
        font-size: 1.2rem;
        a {
            color: $orange_color;
            text-decoration: none !important;
            font-size: 1.5rem;
            &:hover {
                text-decoration: underline;
            }
        }
    }

    p {
        font-size: 0.9rem !important;
        margin-bottom: 0.1rem; 
        span {
            font-weight: 600 !important;
            font-size: 0.9rem !important;
        }
    }

    &__treatment {
        div, p {
            display: inline;
        }
    }

    &__subtitle {
        padding: 0 4px;
        color: $black;
        font-size: 1.2rem;
    }
}
