.my-medecines-text{
  font-size: 1rem !important;
  color: #3B3B3B;
}

.medical-card-title {
  font-size: 26px;
  color: #49414C;
  text-align: center;
  margin-top: 40px;
  margin-bottom: 40px;
}

.my-md-wrapper {
  // width: 850px;
  margin: 3rem auto;
  background-color: white;
  padding: 30px;
  display: flex;
  flex-wrap: wrap;
  padding-top: 0;
  box-shadow: 0px 0px 3px 0px rgba(0,0,0,0.50);
  border-radius: 3px;
  margin-bottom: 50px;

  @media (max-width: 768px) {
    padding: 8px !important; 
  }
}

.my-md-item {
  width: 380px;
  margin-right: 30px;
  margin-top: 30px;
  background-color: white;
  box-shadow: 0px 0px 3px 0px rgba(0,0,0,0.30);
  border-radius: 5px;
  padding: 12px 7px 12px 12px !important;
  display: flex;
}

.my-md-item:hover {
  background-color: #FADCD3 !important;
  box-shadow: 0px 0px 3px 0px rgba(0,0,0,0.65);
}

.my-md-item:nth-child(even) {
  margin-right: 0;
}

.md-item-text-block {
  width: 93%;
}

.md-item-time-date {
  margin-top: 4px;
  font-size: 10px !important;
  color: #898989 !important;
  margin-bottom: 0 !important;
}

.md-item-title {
  margin-top: 7px !important;
  line-height: 14px !important;
}

.line-break {
  width: 100%;
}

.md-item-title, .md-item-desc {
  color: #712C1C !important;
  font-size: 14px !important;
  margin-bottom: 0 !important;
}

.md-item-desc {
  color: #1E1E1E !important;
  margin-top: 3px;
  font-size: 0.8rem !important;
  margin-bottom: 5px !important;
}

.md-item-icons-block img {
  display: block;
  margin-top: 2px !important;
  cursor: pointer;
}

.md-item-icons-block img:nth-child(1) {
  margin-top: 0px !important;
}

.md-item-icons-block img:nth-child(2) {
  margin-left: -4px !important;
}

.md-item-icons-block img:nth-child(3) {
  margin-top: 7px !important;
}

.show-more-btn {
  width: 180px;
  text-align: center;
  padding: 6px 0;
  background-color:#FF6E41;
  color: white;
  border-radius: 8px;
  box-shadow: 0px 1px 2px 0px rgba(0,0,0,0.25);
  margin: 3% 21% 50px auto;
}

.submit-btn {
  box-shadow: none !important;
  border: none !important;
}

.show-more-btn:hover {
  cursor: pointer;
  background-color:  #eb653d;
}

.show-more-btn-title {
  margin: 0;
  color: white !important;
}