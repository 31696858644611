@import "../color_variables";

.my_drug_block {
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    
    margin: 46px;

    border: 1px solid #95989A;
    border-radius: 5px;
    padding: 30px;
    width: 525px;
    box-sizing: border-box;

    .text_block {
        display: inline-flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content:  center;

        p {
            &.date {
                font-size: 1rem;
                color: #898989;
                margin: 0;
            }

            &.content {
                font-size: 1.5rem;
                color: #1E1E1E;
                margin: 0;

            }
        }

        h3.title {
            font-size: 1.5rem;
            color: #712C1C !important;
            margin: 0;

        }
    }

    .buttons_block {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content:  space-between;

        & > .cross_icon {
            margin-top: 8px;
        }
    }
}