.medicines_wrapper {
    width: 100%;

    .medical-card-title {
        font-size: 26px;
        color: #49414c;
        text-align: center;
        margin-top: 40px;
        margin-bottom: 40px;
    }

    .my-md-item {
        width: 280px;
        height: 80px;
        margin-right: 30px;
        margin-top: 30px;
        background-color: #fbfcfd;
        box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.3);
        border-radius: 3px;
        padding: 12px;
        display: flex;
    }

    .my-md-item:nth-child(even) {
        margin-right: 0;
    }

    .md-item-text-block {
        width: 93%;
    }

    .md-item-time-date {
        font-size: 10px;
        color: #898989;
        margin-bottom: 0;
    }

    .md-item-title {
        margin-top: 5px;
        line-height: 14px;
    }

    .md-item-title,
    .md-item-desc {
        color: #0279bd;
        font-size: 14px;
        margin-bottom: 0;
    }

    .md-item-icons-block img {
        display: block;
        margin-top: 3px;
    }

    .md-item-icons-block img:nth-child(1) {
        width: 16px;
        cursor: pointer;
    }

    .md-item-icons-block img:nth-child(2) {
        width: 18px;
        margin-top: 6px;
        margin-left: -2px;
        cursor: pointer;
    }

    .md-item-icons-block img:nth-child(3) {
        width: 11px;
        margin-top: 7px;
        cursor: pointer;
    }
    .medical_card_item_header {
        display: flex;
        border-bottom: 1px solid #d6d6d6;
        flex-wrap: wrap;

        .add-btn {
            padding-bottom: 16px;
        }

        .md_item_title_image {
            display: flex;
            align-items: center;
            height: 62px;
            h1 {
                font-size: 16px !important;
                font-weight: bold;
                margin-bottom: 0;
                white-space: nowrap;
                padding-right: 20px;
                min-width: 208px;
            }
            img {
                height: 1rem;
                width: 1.8rem;
                background-repeat: no-repeat;
                background-position-x: center;
                background-position-y: center;
                margin-left: 20px;
            }
        }
        .button_blck {
            margin-left: auto;
            display: flex;
            align-items: center;
            .show-more-btn {
                font-size: 13px;
                width: 110px;
                // padding: 5px 25px;
                margin: auto;
                // font-weight: bold;

                &:hover {
                    background-color: #ff6e41;
                    color: white;
                }
            }
        }
    }

    .medical_card_item_desc {
        border-bottom: 1px solid #d6d6d6;
        padding: 20px 0 22px 0;

        p {
            font-size: 13px;
            width: 80%;
            margin: 0 auto;
            line-height: 15px;
        }
    }

    .medical_card_item_data_block {
        padding: 10px 20px;
        display: flex;
        flex-wrap: wrap;

        .medical_card_item_title {
            margin: 0;
            font-size: 15px;
            font-weight: bold;
        }
        .button_blck {
            display: flex;
            margin-left: auto;
            .cancel-btn {
                margin-right: 10px;
            }
        }

        .table_data {
            width: 100%;
            margin-top: 15px;
            //max-height: 260px;
            // overflow: hidden;
            overflow-x: auto;
            overflow-y: hidden;

            table {
                width: 100%;
                tr,
                td,
                th {
                    border: 1px solid #d6d6d6;
                    font-size: 11px;
                    font-weight: normal;
                    padding: 5px;
                    text-align: center;
                }

                th {
                    &:nth-of-type(1) {
                        width: 180px;
                    }
                }

                td {
                    &:nth-of-type(1) {
                        display: flex;
                        border: none;
                        margin-left: 10px;
                        div {
                            margin-right: 10px;
                        }
                    }
                }
            }
            .pointer {
                tr {
                    td {
                        cursor: pointer;
                    }
                }
            }
        }
    }
    .drugs-button {
        // width: 110px !important;
        font-weight: normal;
        border-radius: 8px !important;
    }
}

.my-meds-modal-header {
    background-color: #f7f9fc;
    border-bottom: none !important;

    .h4 {
        display: inline-block;
        margin: 0 auto;
        margin-right: -20px;
        padding-right: 20px;
        padding-left: 20px;
        margin-bottom: 0px !important;
    }

    .modal-title {
        margin-bottom: 0px !important;
    }

    padding-bottom: 0px !important;
}

.my-drugs-save {
    float: right;
    margin: 4px;
}

.my-medicines-modal {
    @media screen and (min-width: 768px) {
        width: 768px;
    }
}

.no-items-no-results {
    text-align: center;
    padding: 16px 0;
}

.icon-btn {
    border-radius: 15%;
    padding: 3px 11px;
    background-color: #ff6e41;
    &__icon {
        color: white;
    }
}
