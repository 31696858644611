@import "./../color_variables";
.drugs-az_container {
    display: flex;
    padding: 2rem;
    .drugs-az {
        width: 90%;
        padding: 0 32px 0 0;
        border-radius: 24px;
        &__title {
            color: $second-text-color;
        }
        // &__description {
        //     // padding-top: 1.5em;
        //     // font-size: 1rem;
        // }
        &__search {
            padding-top: 1.5em;
        }
    }
}
@media (max-width: 768px) {
    .drugs-az_container {
        display: block;
        .drugs-az {
            width: unset;
            padding: 0;
        }
    }
}

.alphabet_by_letters {
    width: calc(70% - 25px);
    padding-top: 2rem;
    .alphabet_button {
        &:nth-last-child(2) {
            width: 4rem;
        }
        &:last-child {
            width: 13rem;
        }
    }
}