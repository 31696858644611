@import "../color_variables";

.mainTitle {
  font-size: 1.5em;
  font-weight: bold;
  color: $second-text-color;
}
.aboutus-container {
  h3 {
    color: $second-text-color !important;
  }
}

h1,
h2 {
  margin-top: 0;
}

.aboutus-container {
  padding: 3rem;
}
