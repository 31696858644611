@import "./../color_variables";
.my_drugs_title {
    font-weight: bold;
    color: $black;
}

.my_drugs_body {
    font-size: 1rem;
    color: $black;
    p {
        font-size: 1rem !important;
    }
    a {
        color: $orange_color;
        &:hover {
            text-decoration: underline;
        }
    }
    .my_drugs_blocks {
        margin: -36px -46px -3px -46px;
        display: flex;
        flex-wrap: wrap;
        & > * {
            cursor: pointer;
        }
    }
    .add_drug_button_wrapper {
        display: flex;
        justify-content: flex-end;
        margin-bottom: 88px;
        .add_drug_button {
            font-weight: 400;
            background-color: $orange_color;
            border-radius: 9px;
            border: none;
            outline: none;
            color: white;
            font-size: 1rem;
            padding: 0.3rem 1rem;
            cursor: pointer;
        }
        .big-button {
            font-size: 1.5rem;
        }
    }
    .centred {
        justify-content: center !important;
    }
}

@media (max-width: 768px) {
    .add_drug_button {
        padding: 0.6rem 2rem;
        font-size: 1.5rem;
    }
}
