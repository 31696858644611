@import "../color_variables";

.pill-loader {
  animation: loader-rotation 2s infinite linear;
  height: 5rem;
  width: 5rem;
}

.pill-center-loader {
  display: flex;
  justify-content: center;
}

@keyframes loader-rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359.99deg);
  }
}