@import '../color_variables';
.mainTitleMobile {
    font-size: 1.5em;
    font-weight: bold;
    color: $black;
    margin: 0;
}

.download-app-markets {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.download-app {
    display: inline-block;
    margin: 1rem 0 0 1rem;
}

.download-app:hover {
    cursor: pointer;
}

.padding-bottom-0 {
    padding-bottom: 0;
}