@import "../color_variables";

.container_card {
	width: 100%;
	margin-top: 60px;
}
.card-wrapper {
	display: flex;
	flex-wrap: wrap;
	margin: 40px auto;
	width: 80%;
	&_el {
		margin: 1rem;
	}
	.MuiCard-root {
		overflow: hidden;
		background-color: #ecedee;
		border-radius: 5px;
		padding: 18px;
	}

	.MuiCardContent-root {
		padding: 16px 0 0 0 !important;
		width: 170px;
		height: 64px;
	}

	hr {
		width: 100%;
		height: 2px;
		margin: 17px 0 12px;
		background-color: $orange_color;
	}
	.info-title {
		font-size: 13px;
		color: #016063;
		font-weight: normal;
	}
	.title {
		font-size: 16px;
		font-weight: normal;
		text-align: center;
		margin: 0px;
	}
	p {
		text-align: center;
		color: #016063;
	}

	.md-button {
		margin: 0 auto;
	}
}

.subscription-container {
	padding: 2rem;

	.article-text {
		padding-top: 1rem;
	}
	.block-button {
		padding: 0.5rem;
		display: flex;
		justify-content: center;
		align-items: center;
	}
}