@import "../color_variables";

.news_container {
    background-color: #fff;
    border-radius: 24px 24px 0 0;
}
.news {
    &_date {
    }
    &_type {
        color: $orange_color;
        padding: 0 4px;
    }
}
.mainTitleNews {
    font-size: 1.5em;
    font-weight: bold;
    // color: #1a67a5;
    color: #ff6e41;
    padding-bottom: 0;
}

.article-image {
    float: left;
    width: 40%;
    margin-bottom: 0.9rem;
    margin-right: 2rem;
}

.padding-0 {
    padding-bottom: 0;
}
.article-content {
    padding-right: 1rem;
}
.last-news-main {
    padding: 16px;
    background-color: #fff;
    display: flex;
    &__news-block {
        margin-right: 28px;
        display: flex;
        margin-bottom: 32px;

        img {
            width: 15rem;
            height: 10rem;
            object-fit: cover;
        }

        @media screen and (max-width: 740px) {
            flex-direction: column;
            img {
                width: 18rem;
                margin-left: 24px;
            }
        }
        &__content {
            margin-left: 24px;
            p {
                margin-top: 14px;
                font-size: 14px;
                line-height: 1.2;
                &.news-date {
                    font-size: 11px !important;
                    a {
                        color: #df2d00;
                    }
                }
            }
        }
        // @media (max-width: 768px) {
        //     &__content {
        //         margin-left: 0;
        //     }
        // }
        &:last-child {
            margin-bottom: 0;
        }
    }
    &__news {
        width: 90%;
    }

    .main_content {
        margin-left: -24px;
        .news_title {
            font-weight: bold;
            color: $second-text-color;
            font-size: 1.5rem;
        }
        .news_body {
            display: flex;
            flex-direction: column;
            a {
                color: $orange_color;
                &:hover {
                    text-decoration: underline;
                }
            }
            .popular_requests_container,
            .categories_container {
                & > div {
                    margin-bottom: 15px !important;

                    &:last-of-type {
                        margin-bottom: 0 !important;
                    }

                    & > div {
                        margin-right: 20px !important;
                        width: 25%;
                        // word-break: break-all;

                        &:last-of-type {
                            margin-right: 0 !important;
                        }
                    }
                }
            }

            .popular_requests_container {
                margin-right: 30px;
            }
        }
    }

    .top-ad {
        border: 1px solid #707070;
        height: 300px;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 14px;
        text-align: center;
    }
    .information {
        margin-top: 32px;
        h3 {
            color: #ff6e41 !important;
        }
        a,
        span {
            display: block;
            font-size: 13px;
            line-height: 2;
        }
        a {
            color: $second-text-color !important;
            &:hover {
                color: #ff6e41 !important;
            }
            &:active {
                color: #ff6e41 !important;
            }
        }
    }
    .bottom-ad {
        margin-top: 24px;
    }
}
@media (max-width: 768px) {
    .last-news-main {
        display: flex;
        align-items: center;
        flex-direction: column;
    }
}
