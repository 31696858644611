@import '../color_variables';

.main-title-forgot {
  font-size: 1.5rem;
  color: $black;
  font-weight: bold;
}

.info-block-forgot {
  font-size: 1rem;
}

.sign-in-link {
  color: $blue-button-and-links !important;
  font-weight: bold;
}

.sign-in-link:hover {
  color: $blue-button-and-links-hovered !important;
  cursor: pointer;
  text-decoration: underline !important;
}

.inpusts-sign-in {

  color: $black-form-text;
  width: 40%;
  height: 3rem;
  padding-left: 1rem;
  border: thin solid $gray-form-border;
  background-color: $gray-form-background;
  border-radius: 0.4rem;
}

.reg_button {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  background-color: $blue-button-and-links;
  border-radius: 0.4em;
  text-align: center;
  display: block;

}

.reg_button p {
  color: $white !important;
  font-weight: bold;
  font-size: 1.2rem;
  padding-top: 0.2rem;
  
}

.reg_button:hover {
  background-color: $blue-button-and-links-hovered;
  cursor: pointer;
}

.forgot_password_username {
  font-weight: bold;
  color: $blue-button-and-links !important;
}

.forgot_password_username:hover {
  color: $blue-button-and-links-hovered !important;
  cursor: pointer;
  text-decoration: underline !important;
}

.bottom-margin-del {
  padding-bottom: 0;
}

@media screen and (max-width: 800px) {
  .main-title-forgot {
    white-space: nowrap !important;
  }
  .info-block-forgot {
    width: 88% !important;
    margin-left: 1rem;
  }
}