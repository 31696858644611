@import "./../color_variables";
.side_effects_container {
    padding: 2rem;
    background-color: #fff;
    display: flex;
    border-radius: 24px;
    // margin: 0 -15px;
    width: 100%;
    .side_effects_page {
        width: 90%;
        .side_effects_title {
            font-weight: bold;
            color: $second-text-color;
            font-size: 20px;
        }

        .side_effects_body {
            font-size: 16px;
            color: $black;

            a {
                color: $orange_color;
                &:hover {
                    text-decoration: underline;
                }
            }

            max-width: 1000px;
        }

        .search_card {
            padding-top: 0;
            padding-bottom: 0;

            .search_field {
                margin-top: 10px;
                margin-bottom: 20px;
            }
        }

        .main_content {
            width: 100%;
            margin-left: 0;
        }

        .side_effects_news{
            .last-news-content{
                p {
                    margin-top: 14px;
                    font-size: 14px;
                    line-height: 1.2;
                    &.news-date {
                        font-size: 11px !important;
                        a {
                            color: #df2d00;
                        }
                    }
                }
        }
        }

        .alphabet-block-side-effect {
            width: calc(67% - 25px);
            .alphabet_button {
                &:nth-last-child(2) {
                    width: 4rem;
                }
                &:last-child {
                    width: 13rem;
                }
            }
        }
        @media (max-width: 992px) {
            .alphabet-block-side-effect {
                width: calc(100% - 11px);
            }
        }
    }
}

@media (max-width: 768px) {
    .side_effects_container {
        display: block;
        .side_effects_page{
            width: unset;
        }
    }
    .main_content_sda_wrapper {
        flex-direction: column;

        .sda_block {
            margin-left: auto;
            margin-right: auto;
            margin-bottom: 15px;
        }
    }
}
