.text-input-component-mask {
    text-align: left;
    p {
        font-size: 13px;
        margin-bottom: 0;

        &:nth-of-type(1) {
            margin-top: 10px;
            margin-bottom: 5px;
        }
        &:nth-of-type(2) {
            color: rgb(238, 4, 4);
            margin-left: 2px;
            margin-bottom: -5px;
        }
    }

    input {
        border: 1px solid #95989a;
        height: 33px;
        padding-left: 8px;
        font-size: 13px;
        outline: none;
        border-radius: 5px;
        width: 100%;
    }
}

.personal-cabinet-patient-styles_text-input-mask {
    display: flex;
    width: 80%;
    margin-left: auto;

    p {
        font-size: 13px;
        font-weight: bold;
        color: #404040;

        &:nth-of-type(1) {
            min-width: 100px;
        }
    }
    input {
        font-size: 13px;
        color: #404040;
        border: unset;
        pointer-events: none;
        padding-top: 4px;
    }
}
